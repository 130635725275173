import React, { memo, useCallback, useEffect, useState } from "react";
import {
  TradeOption,
  globalSearchSearchField,
} from "../../utils/DropDownOptions";
import SelectComponent from "../../Component/SelectComponentForExploreNewUI/SelectComponent";
import SearchIcon from "@mui/icons-material/Search";
import Loading from "../../Component/SuccessLoader/success";
import ErrorLoading from "../../Component/ErrorLoader/error";
import { Searching, not_access_country } from "../../utils/LoadingMsg";
import moment from "moment";
import MonthTab from "../../Component/MonthTab/MonthTab";
import ErrorData from "../../utils/ErrorHandling";
import { globalSearchExistingUser } from "../../Services/service";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExploreFilter from "../../Component/CollapseFilterNewUI/Component/ExploreFilter/ExploreFilter";
import GlobalSearchCard from "../../Component/GlobalSearchCard/GlobalSearchCard";
import { Box, Grid } from "@mui/material";
import Filter from "../../Asset/images/filter-white.png";
import { useNavigate } from "react-router";
import ModalComponent from "../../Component/Modal/modal";
import "./GlobalSearch.scss";
import CollapseFilter from "../../Component/CollapseFilterNewUI/CollapseFilter";
import { FILTER_OPTIONS } from "../ExploreCountryShipmentNewUI/Explore.enum";
import {
  divideDateRange,
  getChildValue,
  getDateExpressionUsingDividedDateRange,
} from "../../utils/CommanFunction";
import throwError from "../../utils/throwError";
import { logger } from "../../Loggers/Loggers";
const pageTitle = {
  title: "Global",
  badgeTitle: "Search",
};
const GlobalSearch = memo(() => {
  const navigate = useNavigate();
  const [isConfirmBoxOpen, setConfirmBoxOpen] = useState(false);
  const [errorFromGlobalSearch, setErrorFromGlobalSearch] = useState(false);

  const [isHashValueModelOpen, setHashValueModelOpen] = useState(false);
  const [isFilterLoader, setIsFilterLoader] = useState(false);
  let searchDataValues = {};
  const [navigateUrl, setNavigateUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [selectTrade, setSelectTrade] = useState({
    tradeType: TradeOption[0].value,
    selectedValue: globalSearchSearchField[0].field,
    selectOption: globalSearchSearchField,
    getValuesForSelectDropdown: globalSearchSearchField[0],
    searchValue: "",
  });
  const [isVisibleData, setIsVisibleData] = useState(false);

  const [dateRange, setDateRange] = useState({
    monthRange: 3,
    endMonthDate: "",
    startMonthDate: "",
    minDateForDatePicker: "2019-01-01",
  });
  const [searchData, setSearchData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  //filter
  var removeButtonState = {};
  const [apply, setApply] = useState({
    HS_CODE: false,
    COUNTRY: false,
  });
  const [getAllFilterValueArray, setAllFilterValueArray] = useState([]);
  const [CheckedData, setCheckedData] = useState({});
  const [isFiltersShown, setIsFiltersShown] = useState(true);
  const [headerTitle, setHeaderTitle] = useState("");
  const [initialAllFilterValues, setInitialAllFilterValues] = useState({});
  const [allFilterValuesWithCountsArr, setAllFilterValuesWithCountsArr] =
    useState({});
  const [getFilterValues, setFilterValue] = useState([]);
  const [getAllSplitFilters, setGetAllSplitFilters] = useState();

  const [getAllFilterValues, setGetAllFilterValues] = useState([]);
  const [storeFilteredValues, setStoreFilterValues] = useState({});
  const [appliedFilterData, setAppliedFilterData] = useState({});
  const [keyValuePairForTreeview, setPairForTreeView] = useState({});
  const [valueForTreeview, setValueForTreeView] = useState([]);

  const handleDateRange = (dates, dateStrings, compareDate) => {
    const start = moment(dateStrings[0]).format("YYYY-MM-DD");
    const end = moment(dateStrings[1]).format("YYYY-MM-DD");
    setDateRange({
      ...dateRange,
      startMonthDate: start,
      endMonthDate: end,
    });
  };
  const catchBlock = (err) => {
    const errorForLogger = `Global Search:- ${
      err?.msg ? err?.msg : err?.message
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? ErrorData?.SESSION_TIMEOUT
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };
  const manageDateRange = () => {
    let startDate = "";
    let endDate = "";
    if (dateRange?.monthRange !== 0) {
      if (dateRange?.monthRange === 11) {
        let lastYear = moment(Date()).subtract(1, "year").format("YYYY");
        startDate = moment(new Date(lastYear, 0, 1)).format("YYYY-MM-DD");
        endDate = moment(new Date(lastYear, 12, 0)).format("YYYY-MM-DD");
      } else {
        let lastMonth = moment(new Date())
          .subtract(dateRange?.monthRange, "months")
          .format("YYYY-MM-DD");
        let lastMonthEndMonth = moment(new Date())
          .subtract(1, "months")
          .format("YYYY-MM-DD");
        startDate = moment(lastMonth, "YYYY-MM-DD")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment(lastMonthEndMonth, "YYYY-MM-DD")
          .endOf("month")
          .format("YYYY-MM-DD");
      }
      const dateObj = {
        startMonthDate: startDate,
        endMonthDate: endDate,
      };
      setDateRange({
        ...dateRange,
        startMonthDate: startDate,
        endMonthDate: endDate,
      });
      if (isVisibleData) {
        onSearchClick("", true, dateObj);
      }
    }
  };

  useEffect(() => {
    manageDateRange();
  }, [dateRange.monthRange]);
  const handelChangeSelect = (e) => {
    const { name, value } = e?.target;
    if (name === "selectedValue") {
      const _getValuesForSelectDropdown =
        globalSearchSearchField.find((e) => e?.field === value) ?? {};
      setSelectTrade({
        ...selectTrade,
        [name]: value,
        getValuesForSelectDropdown: _getValuesForSelectDropdown,
      });
    } else {
      setSelectTrade({ ...selectTrade, [name]: value });
    }
    if (value !== "") {
      setErrorFromGlobalSearch(false);
    }
  };

  const formulateDataGlobalSearch = (isCheckedEmpty) => {
    let recordsPayload = {
      key: selectTrade?.getValuesForSelectDropdown.identifier,
      startDate: dateRange?.startMonthDate,
      endDate: dateRange?.endMonthDate,
      tradeType: selectTrade?.tradeType,
    };

    let valueArr = [];
    valueArr.push(selectTrade?.searchValue);
    recordsPayload.value = valueArr;

    let _CheckedData = { ...CheckedData };
    if (CheckedData && Object.keys(CheckedData)?.length) {
      for (const [key, value] of Object.entries(CheckedData)) {
        if (Object.keys(value).length === 0) {
          delete _CheckedData[key];
        }
      }
    }
    if (isCheckedEmpty) {
      _CheckedData = {};
    }
    let hsCode = [];
    if (_CheckedData.HS_CODE) {
      for (const [key, value] of Object.entries(_CheckedData.HS_CODE)) {
        const newkey = key.replace(/^0+/, "");
        hsCode.push(newkey);
      }
    }
    if (hsCode?.length > 0) {
      recordsPayload.filter_hs_code = hsCode;
    }

    let dataType = [];
    if (_CheckedData.DATATYPE) {
      for (const [key, value] of Object.entries(_CheckedData?.DATATYPE)) {
        dataType.push(key.split("_").join(" "));
      }
    }
    if (dataType.length > 0) {
      recordsPayload.country = dataType;
    }
    return recordsPayload;
  };
  const packDTParams = (isCheckedEmpty) => {
    let data = formulateDataGlobalSearch(isCheckedEmpty);
    return data;
  };
  const setFilterDataBasedOnSearch = (e) => {
    if (e) {
      setInitialAllFilterValues(e);
      let hsCode = [];
      if (e && e?.FILTER_HS_CODE) {
        hsCode = [...e?.FILTER_HS_CODE];
      }
      let dataType = [];
      if (e && e?.DATATYPE) {
        dataType = [...e?.DATATYPE];
      }
      filterHSCode(hsCode, dataType, true);
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData.SOMETHING_WENT_WRONG,
      });
    }
  };
  const filterHSCode = (arrValueHsCodeData, dataType, isCheckedEmpty) => {
    const groupedHsCodeData = {};
    arrValueHsCodeData.forEach((item) => {
      const parentId = item?._id.toString().substring(0, 2);
      const childId = item?._id.toString().substring(0, 4);
      if (!groupedHsCodeData[parentId]) {
        groupedHsCodeData[parentId] = {
          _id: parentId,
          isChecked: false,
          children: [],
        };
      }

      const parentObj = groupedHsCodeData[parentId];

      if (!parentObj.children.find((child) => child._id === childId)) {
        parentObj?.children.push({
          _id: childId,
          isChecked: false,
          children: [],
        });
      }

      let childObj = parentObj?.children?.find(
        (child) => child._id === childId
      );
      childObj.children.push({
        _id: item?._id.toString(),
        isChecked:
          CheckedData &&
          CheckedData.hasOwnProperty(FILTER_OPTIONS?.HS_CODE) &&
          Object.keys(CheckedData[FILTER_OPTIONS?.HS_CODE])?.length > 0 &&
          Object.keys(CheckedData[FILTER_OPTIONS?.HS_CODE]).includes(
            item?._id?.toString()
          ) &&
          !isCheckedEmpty
            ? true
            : false,
      });
    });

    let hsCode = Object.values(groupedHsCodeData)?.map((ele) => {
      ele?.children &&
        ele?.children?.length > 0 &&
        ele?.children?.map((child) => {
          child?.children &&
            child?.children?.length > 0 &&
            child?.children?.map((subChild) => {
              return subChild;
            });
          child.isChecked = getChildValue(child?.children);
          return child;
        });
      ele.isChecked = getChildValue(ele?.children) ? true : false;
      return ele;
    });

    const customCountryData =
      dataType.filter((e) => e?.countryValue === "output") ?? [];
    const blCountryData =
      dataType.filter((e) => e?.countryValue === "bl_output") ?? [];
    const customCountry = [];
    const blCountry = [];
    const customData = [];
    const blData = [];
    customCountryData.length > 0 &&
      customCountryData.forEach((e) => {
        if (
          !customCountry.includes(e?._id) &&
          !customCountry.includes(e?._id?.toLowerCase())
        ) {
          customData.push({
            _id: e?._id.split("_").join(" "),
            countryValue: e?.countryValue,
            isChecked:
              CheckedData &&
              CheckedData.hasOwnProperty(FILTER_OPTIONS?.DATATYPE) &&
              Object.keys(CheckedData[FILTER_OPTIONS?.DATATYPE])?.length > 0 &&
              Object.keys(CheckedData[FILTER_OPTIONS?.DATATYPE]).includes(
                e?._id?.toString()
              ) &&
              !isCheckedEmpty
                ? true
                : false,
          });
          customCountry.push(e?._id);
        }
      });
    blCountryData?.length > 0 &&
      blCountryData.forEach((e) => {
        if (
          !blCountry.includes(e?._id) &&
          blCountry.includes(e?._id?.toLowerCase())
        ) {
          blData.push({
            _id: e?._id.split("_").join(" "),
            countryValue: e?.countryValue,
            isChecked:
              CheckedData &&
              CheckedData.hasOwnProperty(FILTER_OPTIONS?.DATATYPE) &&
              Object.keys(CheckedData[FILTER_OPTIONS?.DATATYPE])?.length > 0 &&
              Object.keys(CheckedData[FILTER_OPTIONS?.DATATYPE]).includes(
                e?._id?.toString()
              ) &&
              !isCheckedEmpty
                ? true
                : false,
          });
          blCountry.push(e?._id);
        }
      });
    let data = [
      {
        children: blData,
        _id: "BL COUNTRY",
        isChecked: false,
      },
      {
        children: customData,
        _id: "CUSTOM COUNTRY",
        isChecked: false,
      },
    ];
    setPairForTreeView({
      HS_CODE: hsCode ?? [],
      DATATYPE: data,
    });
  };
  const onSearchClick = (e, monthFilter, dateObj) => {
    if (selectTrade?.searchValue === "") {
      setErrorFromGlobalSearch(true);
      return;
    }
    setLoading(true);
    setLoadingMsg(Searching);
    let payload = packDTParams(true);
    if (monthFilter) {
      payload.startDate = dateObj?.startMonthDate;
      payload.endDate = dateObj?.endMonthDate;
    }
    globalSearchExistingUser(payload)
      .then((res) => {
        if (res?.status === 500 || res?.status === 401) {
          throw throwError(res);
        } else {
          if (res?.data) {
            const { countryNames, output, bl_output } = res?.data?.result;
            setCountryData(countryNames);
            const data = recordAndFilterDataFormat(output, bl_output);
            searchDataValues = data?.record;
            setSearchData(() => data?.record);
            setGetAllSplitFilters(data?.filter);
            setAllFilterValuesWithCountsArr(data?.filter);
            setAllFilterValueArray(data?.filter);
            setFilterDataBasedOnSearch(data?.filter);
            setIsVisibleData(true);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  const recordAndFilterDataFormat = (output, bl_output) => {
    const arr = {
      BL_COUNTRY: [],
      CUSTOM_COUNTRY: [],
    };
    const filterObj = {
      DATATYPE: [],
      FILTER_HS_CODE: [],
    };
    let filterHsCodeArray = [];
    const dataTypeCountryArr = [];
    output.length > 0 &&
      output.forEach((custom) => {
        Object.keys(custom).forEach((val) => {
          filterHsCodeArray.push(custom[val]?.FILTER_HS_CODE);
          if (custom[val]?.RECORD_SET?.length > 0) {
            if (!dataTypeCountryArr.includes(val)) {
              filterObj.DATATYPE.push({
                _id: val,
                countryValue: "output",
              });
              dataTypeCountryArr.push(val);
            }
            arr.CUSTOM_COUNTRY.push(custom);
          }
        });
      });
    bl_output.length > 0 &&
      bl_output.forEach((bl) => {
        Object.keys(bl).forEach((blVal) => {
          filterHsCodeArray.push(bl[blVal]?.FILTER_HS_CODE);
          if (bl[blVal]?.RECORD_SET?.length > 0) {
            filterObj.DATATYPE.push({
              _id: blVal,
              countryValue: "bl_output",
            });
            arr.BL_COUNTRY.push(bl);
          }
        });
      });

    filterHsCodeArray = filterHsCodeArray.flat();
    filterObj.FILTER_HS_CODE = filterHsCodeArray;
    let hsCodeFilterArray = [];
    let hsCodeArr = [];
    filterObj?.FILTER_HS_CODE.length > 0 &&
      filterObj?.FILTER_HS_CODE?.forEach((e) => {
        if (!hsCodeArr.includes(e?._id)) {
          let matchHsCodeArr = filterObj?.FILTER_HS_CODE.filter(
            (obj) => obj?._id === e?._id
          );
          let hsCodeObj = {
            _id: e?._id,
            count: null,
          };
          let count = 0;
          matchHsCodeArr?.length > 0 &&
            matchHsCodeArr?.forEach((val) => {
              count = count + val?.count;
            });
          hsCodeObj.count = count;
          hsCodeFilterArray.push(hsCodeObj);
          hsCodeArr.push(e?._id);
        }
      });

    let object = {
      FILTER_HS_CODE: hsCodeFilterArray,
      DATATYPE: filterObj?.DATATYPE,
    };
    const sortedWiseData = sortWiseData(arr);
    const allData = {
      record: sortedWiseData,
      filter: object,
    };
    return allData;
  };
  const sortWiseData = (data) => {
    let sortedArr = {
      BL_COUNTRY: [],
      CUSTOM_COUNTRY: [],
    };
    sortedArr.BL_COUNTRY =
      data?.BL_COUNTRY &&
      data?.BL_COUNTRY &&
      data?.BL_COUNTRY.sort(function (a, b) {
        return (
          b[Object.keys(b)].SUMMARY_RECORDS[0].count -
          a[Object.keys(a)].SUMMARY_RECORDS[0]?.count
        );
      });

    sortedArr.CUSTOM_COUNTRY =
      data?.CUSTOM_COUNTRY &&
      data?.CUSTOM_COUNTRY &&
      data?.CUSTOM_COUNTRY.sort(function (a, b) {
        return (
          b[Object.keys(b)].SUMMARY_RECORDS[0].count -
          a[Object.keys(a)].SUMMARY_RECORDS[0]?.count
        );
      });
    return sortedArr;
  };

  const RemoveAppliedFilter = (index, data) => {
    const all = { ...storeFilteredValues };
    const _CheckedData = { ...CheckedData };
    // delete all["VIEW_COLUMNS"];
    let header = data[index][0];

    setApply({
      ...apply,
      [header]: false,
    });

    if (headerTitle === FILTER_OPTIONS?.HS_CODE) {
      const arr = [...keyValuePairForTreeview[headerTitle]];
      arr.map((e) => {
        e.isChecked = false;
        const children = [...e.children];
        children.map((child) => {
          child.isChecked = false;
        });
      });
      const treeViewArr = [...valueForTreeview];
      treeViewArr.map((val) => {
        const arr = [...val.children];
        arr.map((ar) => {
          ar.isChecked = false;
        });
      });
    }

    removeButtonState = {
      ...removeButtonState,
      [headerTitle]: true,
    };
    delete _CheckedData[header];
    delete all[header];
    setStoreFilterValues(all);
    setCheckedData(_CheckedData);

    if (Object.keys(_CheckedData).length === 0) {
      filterOnSearchClick(true, header);
    }
  };
  const filterOnSearchClick = (isCheckedEmpty) => {
    setLoading(true);
    setLoadingMsg(Searching);
    const _CheckedData = { ...CheckedData };
    for (const [key, value] of Object.entries(_CheckedData)) {
      if (Object.keys(value).length === 0) {
        delete _CheckedData[key];
        setCheckedData(_CheckedData);
        setStoreFilterValues(_CheckedData);
      }
    }

    let recordsPayload = packDTParams(isCheckedEmpty);
    globalSearchExistingUser(recordsPayload)
      .then((res) => {
        if (res?.status === 500 || res?.status === 401) {
          throw throwError(res);
        } else {
          if (res?.data) {
            const { countryNames, output, bl_output } = res?.data?.result;
            setCountryData(countryNames);
            const data = recordAndFilterDataFormat(output, bl_output);
            searchDataValues = data?.record;
            setSearchData(() => data?.record);
            setGetAllSplitFilters(data?.filter);
            setAllFilterValuesWithCountsArr(data?.filter);
            setFilterBasedOnFilter(data?.filter, isCheckedEmpty);
            setIsVisibleData(true);
            setLoading(false);
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  const setFilterBasedOnFilter = (e, isCheckedEmpty) => {
    if (e) {
      let data = getAllFilterValueArray;
      let hsCode = [];
      let dataType = [];
      if (data) {
        hsCode = data?.FILTER_HS_CODE;
        dataType = data?.DATATYPE;
      }
      filterHSCode(hsCode, dataType, isCheckedEmpty);
    } else {
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData.SOMETHING_WENT_WRONG,
      });
    }
  };
  const handleOpenModel = (
    data,
    value,
    currentCountry,
    trade,
    blFlag,
    date
  ) => {
    let countryCode = countryData.filter(
      (e) => e.country.toUpperCase() == currentCountry
    )[0]?.code_iso_3;
    let currentCountryVal = currentCountry.split(" ").join("_");
    if (data) {
      setHashValueModelOpen(true);
    } else {
      let obj = {
        ChooseSearchTerm: selectTrade?.searchValue,
        searchValue: selectTrade?.getValuesForSelectDropdown,
        monthRange: dateRange?.monthRange,
        isRun: true,
        endDate: date?.endDate,
        startDate: date?.startDate,
      };
      localStorage.setItem("globalSearchData", JSON.stringify(obj));
      setConfirmBoxOpen(true);
      setNavigateUrl(
        `/countries/shipments/explorer?tradeType=${trade.toUpperCase()}&countryCode=${countryCode}&country=${currentCountryVal.toUpperCase()}&blFlag=${blFlag}&globalSearch=true`
      );
    }
  };
  const filterOptionClick = (name) => {
    switch (name) {
      case FILTER_OPTIONS?.HS_CODE:
        setHeaderTitle(FILTER_OPTIONS?.HS_CODE);
        break;
      case FILTER_OPTIONS?.DATATYPE:
        setHeaderTitle(FILTER_OPTIONS?.DATATYPE);
        break;
      default:
        break;
    }
  };

  const handleToggleFilters = () => {
    setIsFiltersShown(!isFiltersShown);
  };
  const handleApplyFilter = (e) => {
    setApply({
      ...apply,
      [headerTitle]: true,
    });

    removeButtonState = {
      ...removeButtonState,
      [headerTitle]: false,
    };
  };
  useEffect(() => {
    let _appliedFilterData = { ...appliedFilterData };
    if (_appliedFilterData && Object.keys(_appliedFilterData)?.length) {
      delete _appliedFilterData[FILTER_OPTIONS?.VIEW_COLUMNS];
    }
    if (apply && Object.keys(apply)?.length > 0) {
      for (const [keys, values] of Object.entries(apply)) {
        if (!values) {
          delete _appliedFilterData[keys];
        }
      }
    }
    if (JSON.stringify(CheckedData) !== JSON.stringify(_appliedFilterData)) {
      setCheckedData(_appliedFilterData);
    }
  }, [apply]);

  useEffect(() => {
    if (headerTitle !== FILTER_OPTIONS?.VIEW_COLUMNS) {
      setAppliedFilterData(storeFilteredValues);
    }
  }, [storeFilteredValues]);

  if (appliedFilterData) {
    for (const [key, value] of Object.entries(appliedFilterData)) {
      for (const [keys, values] of Object.entries(value)) {
        if (!values && values !== 0) {
          const _appliedFilterData = appliedFilterData;
          delete _appliedFilterData[key][keys];
          setAppliedFilterData(_appliedFilterData);
        }
      }
    }
  }
  useEffect(() => {
    if (CheckedData && Object.keys(CheckedData).length > 0) {
      filterOnSearchClick();
    }
  }, [CheckedData]);

  const globalSearchSelectComponent = useCallback(() => {
    return (
      <>
        <SelectComponent
          selectTrade={selectTrade}
          handelChangeSelect={handelChangeSelect}
          isGlobalSearch={true}
          pageTitle={pageTitle}
          btnShow={true}
          btnIcon={SearchIcon}
          btnName={"Search"}
          btnClick={onSearchClick}
          errorFromGlobalSearch={errorFromGlobalSearch}
        />
      </>
    );
  }, [selectTrade, errorFromGlobalSearch]);

  const globalSearchMonthTab = useCallback(() => {
    return (
      <MonthTab
        dateRange={dateRange}
        setDateRange={setDateRange}
        handleDateRange={handleDateRange}
        onSearchClick={onSearchClick}
        isGlobalSearch={true}
      />
    );
  }, [dateRange]);
  return (
    <div id="global-search">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && (
        <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />
      )}
      {isHashValueModelOpen && (
        <ErrorLoading
          errorMsg={not_access_country}
          open={isHashValueModelOpen}
          setOpen={setHashValueModelOpen}
        />
      )}
      {isConfirmBoxOpen && (
        <ModalComponent
          open={isConfirmBoxOpen}
          setOpen={setConfirmBoxOpen}
          header="Confirm Box"
          modalBody={
            <span
              style={{
                fontSize: "18px",
                fontWeight: 600,
                color: "rgb(50, 58, 70)",
              }}
            >
              Are you sure you want to redirect in explore?
            </span>
          }
          width="600px"
          saveText="Confirm"
          saveButtonShown={true}
          saveButtonColor="#005D91"
          isConfirmBoxOpen={isConfirmBoxOpen}
          navigateUrl={navigateUrl}
        />
      )}
      <div className="paddingAllLeftRight">
        <div>{globalSearchSelectComponent()}</div>
        {isVisibleData && <div>{globalSearchMonthTab()}</div>}
      </div>
      {isVisibleData && (
        <div className="globalSearch-filter" style={{ marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid
              className="globalSearchFilter-div"
              item
              xs={isFiltersShown ? 2.5 : 0}
              display={isFiltersShown ? "" : "none"}
            >
              <Box width="100%" id="globalSearch"></Box>
              <CollapseFilter
                isFiltersShown={isFiltersShown}
                handleApplyFilter={handleApplyFilter}
                setIsFiltersShown={setIsFiltersShown}
                filterOptionClick={filterOptionClick}
                headerTitle={headerTitle}
                keyValuePairForTreeview={keyValuePairForTreeview}
                allFilterValuesWithCountsArr={allFilterValuesWithCountsArr}
                getAllFilterValues={getAllFilterValues}
                getFilterValues={getFilterValues}
                isFromMarket={true}
                isGlobalSearch={true}
                setFilterValue={setFilterValue}
                currentCountry={selectTrade?.country}
                storeFilteredValues={storeFilteredValues}
                setStoreFilterValues={setStoreFilterValues}
                CheckedData={CheckedData}
                appliedFilterData={appliedFilterData}
                setPairForTreeView={setPairForTreeView}
                isFilterLoader={isFilterLoader}
                appliedFilterComp={
                  <ExploreFilter
                    CheckedData={CheckedData}
                    isFromExplore={true}
                    RemoveAppliedFilter={RemoveAppliedFilter}
                    apply={apply}
                    quantityData={[]}
                    handleToggleFilters={handleToggleFilters}
                  />
                }
              />
            </Grid>
            {!isFiltersShown && (
              <Box className="filter-label-off-globalSearch">
                <Box
                  id="set-filters-showing-arrow-globalSearch"
                  style={{
                    zIndex: isVisibleData && !isFiltersShown ? 10000 : "",
                  }}
                  onClick={() => setIsFiltersShown(!isFiltersShown)}
                >
                  <img
                    src={Filter}
                    alt="filter"
                    width="20px"
                    height="20px"
                  ></img>

                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                    }}
                  />
                </Box>
              </Box>
            )}
            <Grid item xs={isFiltersShown ? 9.5 : 12}>
              <div className="globalSearch-data-container">
                <span
                  className="globalSearch-country-label"
                  style={{ paddingLeft: isFiltersShown ? "" : "130px" }}
                >
                  Custom Countries
                </span>
                {!!searchData.CUSTOM_COUNTRY?.length &&
                  searchData.CUSTOM_COUNTRY?.map((el) => {
                    return (
                      <>
                        {Object.keys(el).map((val, index) => {
                          return (
                            <>
                              <GlobalSearchCard
                                data={el[val]}
                                countryName={val.toUpperCase(0)}
                                country={countryData}
                                currentCountry={Object.keys(
                                  el
                                )[0].toUpperCase()}
                                handleOpenModel={handleOpenModel}
                                key={index}
                                bl_flag={false}
                              />
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                <span
                  className="globalSearch-country-label"
                  style={{
                    width: "99%",
                    display: "inline-block",
                    paddingLeft: isFiltersShown ? "" : "130px",
                  }}
                >
                  BL Countries
                </span>
                {!!searchData.BL_COUNTRY?.length &&
                  searchData.BL_COUNTRY?.map((el) => {
                    return (
                      <>
                        {Object.keys(el).map((val, index) => {
                          return (
                            <>
                              <GlobalSearchCard
                                data={el[val]}
                                countryName={val.toUpperCase(0)}
                                country={countryData}
                                currentCountry={Object.keys(
                                  el
                                )[0].toUpperCase()}
                                handleOpenModel={handleOpenModel}
                                key={index}
                                bl_flag={false}
                              />
                            </>
                          );
                        })}
                      </>
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
});

export default GlobalSearch;
