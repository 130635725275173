import React from "react";
import "./RecentUserActivity.scss";
import DataUpdate from "./Component/DataUpdateTable/DataUpdateTable";
import RecentSearchTable from "./Component/RecentSearchTable/RecentSearchTable";

const RecentUserActivity = ({
  handleClickForRecentActivity,
  recentUserActivityData,
}) => {
  return (
    <>
      <div className="recent-activity-country-update-container">
        <RecentSearchTable
          RecentSearchData={recentUserActivityData}
          handleClickForRecentActivity={handleClickForRecentActivity}
        />
        <DataUpdate />
      </div>
    </>
  );
};

export default RecentUserActivity;
