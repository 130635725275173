import React, { useState } from "react";
import "./PasswordReset.scss";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Logo from "../../Asset/images/logo-dark-og-transparent.png";
import loginImage from "../../Asset/images/Reset password-amico (1).png";
import userAvatar from "../../Asset/images/user-avatar.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  getLoginDetails,
  getPasswordResetDetails,
} from "../../Services/service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Loading from "../../Component/SuccessLoader/success";
import Error from "../../Component/ErrorLoader/error";
import ErrorData from "../../utils/ErrorHandling";
import LoginPassword from "../../Component/LoginPasswordComponent/loginPassword";
import AuthFooter from "../../Component/AuthFooter/authFooter";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  password: Yup.string()
    .required("This value is required.")
    .matches(
      /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Z]).{8,16}$/,
      "Please enter strong password"
    ),
  reTypePassword: Yup.string()
    .required("This value is required.")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
});

const PasswordReset = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState({});

  const search = useLocation().search;
  const userId = new URLSearchParams(search).get("id");
  const userid = new URLSearchParams(search).get("userid");
  const adminUserId = new URLSearchParams(search).get("adminuserid");
  const staySubUser = new URLSearchParams(search).get("staysubuser");

  function resetPassword(values) {
    setloading(true);
    let resetDetails = {};
    resetDetails.passwordId = userId;
    resetDetails.password = values.password;

    getPasswordResetDetails(resetDetails)
      .then((resetPasswordResponse) => {
        if (resetPasswordResponse.status === 200) {
          setTimeout(() => {
            navigate(`/password/verify`, {
              state: {
                userId: userId,
                userid: userid,
                adminUserId: adminUserId,
                staySubUser: staySubUser,
              },
            });
            setloading(false);
          }, 1000);
        } else {
          setloading(false);
          seterror(true);
          seterrorMsg({
            title: resetPasswordResponse.data.type,
            content: resetPasswordResponse.data.msg,
            open: true,
          });
        }
      })
      .catch((e) => {});
  }

  return (
    <div className="login-test-one-container">
      {error && <Error errorMsg={errorMsg} open={error} setOpen={seterror} />}
      {loading && (
        <Loading title={"Please Wait..."} content={"Reseting your password"} />
      )}
      <div className="container-one">
        <div className="container-one-image-content">
          <div className="login-content-image-container">
            <img id="login-content-img-one" src={loginImage} />
          </div>
          <h1 className="login-image-content-one">
            Step 3: Reset Your Password
          </h1>
          <p className="login-image-content-para-one">
            you can create a new password for your account. Make sure to choose
            a strong and secure password to keep your account safe.
          </p>
        </div>
      </div>
      <div className="container-two">
        <div id="container-two-div">
          <div className="login-form-div">
            <div className="login-test-one-logo-div">
              <img
                src={Logo}
                // width={300}
                // height={100}
                id="login-test-one-logo"
                alt="logo"
              />
            </div>
            <div className="form-container-with-image-one">
              <div>
                <Formik
                  initialValues={{
                    reTypePassword: "",
                    password: "",
                  }}
                  validationSchema={DisplayingErrorMessagesSchema}
                  onSubmit={(values) => {
                    resetPassword(values);
                  }}
                >
                  {({ errors, touched, values }) => (
                    <LoginPassword
                      login="false"
                      text="Enter your password and confirm password to reset the password."
                      subject1="Password"
                      type1="password"
                      name1="password"
                      subject1Placeholder="Password"
                      touched1={touched.password}
                      errors1={errors.password}
                      subject2="Confirm Password"
                      type2="password"
                      name2="reTypePassword"
                      subject2Placeholder=" Confirm Password"
                      touched2={touched.reTypePassword}
                      errors2={errors.reTypePassword}
                      buttonText="Submit"
                      isShowing={true}
                      values={values}
                    />
                  )}
                </Formik>
              </div>
            </div>
            <div className="bottom-paragraph-test-one">
              <AuthFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PasswordReset;
