import React from "react";
import "./RequestCompanyModal.scss";
import { Button, Typography } from "@mui/material";
import upgradeImg2 from "../../../Asset/images/upgrade2.png";

const RequestCompanyModal = ({
  onClick,
  countryName,
  headerTitle,
  headerIcon,
  handleCancel,
}) => {
  return (
    <div>
      <div className="modal-header" style={{ display: "flex" }}>
        {headerIcon && (
          <div className="header-icon" style={{ color: "#4c97e3" }}>
            {headerIcon}
          </div>
        )}
        <Typography className="headerTitle" variant="h6" component="h2">
          {headerTitle}
        </Typography>
        <span className="close-icon" onClick={handleCancel}>
          ×
        </span>
      </div>

      <hr></hr>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <img
          src={upgradeImg2}
          alt="no-img"
          style={{ width: "18%", marginTop: "12px" }}
        />
        <div style={{ width: "70%", fontSize: "15px", marginTop: "20px" }}>
          Upgrade your Plan to access {countryName} Trades! <br />
          <br />
          <span style={{ color: "gray" }}>
            Use the Ticket Manager to contact us and upgrade your plan.
          </span>
        </div>
      </div>

      <div style={{ textAlign: "end", marginTop: "15px" }}>
        <Button
          onClick={onClick}
          sx={{
            backgroundColor: "#4c97e3",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#357ab7",
            },
          }}
          variant="contained"
        >
          Upgrade
        </Button>
      </div>
    </div>
  );
};

export default RequestCompanyModal;
