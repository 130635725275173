import React, { memo, useEffect } from "react";
import "./MarketProduct.scss";
import SelectComponent from "../../../Component/SelectComponentForExploreNewUI/SelectComponent";
import SearchIcon from "@mui/icons-material/Search";
import MonthTab from "../../../Component/MonthTab/MonthTab";
import { useState } from "react";
import { CountryOptionsForMarket } from "../../../utils/DropDownOptions";
import {
  getRecordShipmentData,
  marketProductRecordAndFilter,
  marketProductRecord,
  getTaxonomyCountryList,
} from "../../../Services/service";
import Loading from "../../../Component/SuccessLoader/success";
import ErrorLoading from "../../../Component/ErrorLoader/error";
import {
  DownLoad_Excel_Notify,
  DownLoad_Excel_SuccessFull,
  Market_Country,
  RecordLimit_200,
  Searching,
} from "../../../utils/LoadingMsg";
import ErrorData from "../../../utils/ErrorHandling";
import {
  divideDateRange,
  getChildValue,
  getCookie,
  getDateExpressionUsingDividedDateRangeForMarketPlace,
  getRangeFromOption,
  sanitizeHsCodeDigitBoundaries,
  translateButtonClick,
} from "../../../utils/CommanFunction";
import moment from "moment";
import {
  FILTER_OPTIONS,
  SHIPMENT_RESULT_TYPE_RECORDS,
} from "../../ExploreCountryShipmentNewUI/Explore.enum";
import CompareTable from "../../../Component/CompareTable/CompareTable";
import throwError from "../../../utils/throwError";
import { ProductTableHeader } from "../MarketCountry/MarketCountry.enum";
import { Box, Grid } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Filter from "../../../Asset/images/filter-white.png";
import CollapseFilter from "../../../Component/CollapseFilterNewUI/CollapseFilter";
import ExploreFilter from "../../../Component/CollapseFilterNewUI/Component/ExploreFilter/ExploreFilter";
import Iframe from "../../../Component/IframeForMarketPlace/iframeDashboard";
import ShipmentFilter from "../../../utils/ShipmentFilterOptions";
import valueFilter from "../../../utils/ValueFiltersOptions";
import MarketSummary from "../Component/MarketSummary";
import { logger } from "../../../Loggers/Loggers";

const pageTitle = {
  title: "Market",
  badgeTitle: "Product",
};
const urlHost = process.env.REACT_APP_BASE_LIVE;

const MarketProduct = memo(() => {
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [selectTrade, setSelectTrade] = useState({
    tradeType: "IMPORT",
    country: "INDIA",
    CountryOptions: CountryOptionsForMarket,
    value: "DEFAULT ALL",
    minValue: 0,
    maxValue: 0,
    valueFilterRangeFlag: false,
    hsCode: 2,
    groupBy: "SELECT",
  });
  const [isRecordBtnChecked, setIsRecordBtnChecked] = useState(true);
  const [dateRange, setDateRange] = useState({
    minDateForDatePicker: "",
    monthRange: 1,
    maxDateForEndMonth: "",
    endMonthDate: "",
    startMonthDate: "",
    compareEndDate: "",
    compareStartDate: "",
  });
  const [isVisibleData, setIsVisibleData] = useState(false);
  const [exploreShipmentSpecification, setExploreShipmentRecord] = useState({});
  //filter
  const [isFiltersShown, setIsFiltersShown] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [isFilterLoader, setIsFilterLoader] = useState(false);
  const [getFilterValues, setFilterValue] = useState([]);
  const [getAllFilterValues, setGetAllFilterValues] = useState([]);
  const [getAllFilterValueArray, setAllFilterValueArray] = useState([]);
  const [allFilterValuesWithCountsArr, setAllFilterValuesWithCountsArr] =
    useState({});
  const [getAllSplitFilters, setGetAllSplitFilters] = useState();
  const [initialAllFilterValues, setInitialAllFilterValues] = useState({});
  const [keyValuePairForTreeview, setPairForTreeView] = useState({});
  const [storeFilteredValues, setStoreFilterValues] = useState({});
  const [appliedFilterData, setAppliedFilterData] = useState({});
  const [CheckedData, setCheckedData] = useState({});
  const [apply, setApply] = useState({
    HS_CODE: false,
  });
  var removeButtonState = {};
  //pagination
  const [totalRecord, setTotalRecord] = useState({});
  //table
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [totalAvailableData, setTotalAvailableData] = useState();
  const [risonQuery, setRisonQuery] = useState("");
  const [compareRisonQuery, setCompareRisonQuery] = useState("");
  const catchBlock = (err) => {
    const errorForLogger = `MarketPlace ProductSearch:- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? ErrorData?.SESSION_TIMEOUT
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };
  const [viewId, setViewId] = useState("");

  useEffect(() => {
    let _countryOptionsList = [];
    getTaxonomyCountryList()
      .then((response) => {
        if (response?.data) {
          const countryListData = response?.data;
          countryListData.map((country) => {
            if (
              _countryOptionsList.every(
                (option) => option.value !== country?.country?.toUpperCase()
              )
            ) {
              _countryOptionsList.push({
                value: country?.country?.toUpperCase(),
                title: country?.country?.toUpperCase(),
              });
            }
            return null;
          });
          setSelectTrade((prevState) => ({
            ...prevState,
            CountryOptions: _countryOptionsList,
          }));
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        setError(true);
      });
  }, []);

  useEffect(() => {
    const { min, max } = getRangeFromOption(selectTrade?.value);
    setSelectTrade({
      ...selectTrade,
      minValue: min,
      maxValue: max,
    });
  }, [selectTrade?.value]);

  const handelChangeSelect = (e) => {
    const { name, value } = e?.target;
    if (name === "value") {
      setSelectTrade({
        ...selectTrade,
        valueFilterRangeFlag: true,
        ...selectTrade,
        [name]: value,
      });
    } else {
      setSelectTrade({ ...selectTrade, [name]: value });
    }
  };
  const manageDateRange = () => {
    let startDate = "";
    let endDate = "";
    let _compareStartDate = "";
    let _compareEndDate = "";
    if (dateRange?.monthRange !== 0) {
      if (dateRange?.monthRange === 11) {
        let lastYear = moment(Date()).subtract(1, "year").format("YYYY");
        startDate = moment(new Date(lastYear, 0, 1)).format("YYYY-MM-DD");
        endDate = moment(new Date(lastYear, 12, 0)).format("YYYY-MM-DD");
        _compareStartDate = moment(new Date(lastYear - 1, 0, 1)).format(
          "YYYY-MM-DD"
        );
        _compareEndDate = moment(new Date(lastYear - 1, 12, 0)).format(
          "YYYY-MM-DD"
        );
      } else {
        let lastMonth = moment(new Date())
          .subtract(dateRange?.monthRange, "months")
          .format("YYYY-MM-DD");
        let lastMonthEndMonth = moment(new Date())
          .subtract(1, "months")
          .format("YYYY-MM-DD");
        startDate = moment(lastMonth, "YYYY-MM-DD")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment(lastMonthEndMonth, "YYYY-MM-DD")
          .endOf("month")
          .format("YYYY-MM-DD");

        let monthToSubtract = dateRange?.monthRange * 2;
        let secondLastMonth = moment(new Date())
          .subtract(monthToSubtract, "months")
          .format("YYYY-MM-DD");
        _compareStartDate = moment(secondLastMonth, "YYYY-MM-DD")
          .startOf("month")
          .format("YYYY-MM-DD");
        const dateForSecondPairEndDate = moment(_compareStartDate)
          .add(dateRange?.monthRange, "month")
          .subtract(1, "month")
          .format("YYYY-MM-DD");
        _compareEndDate = moment(dateForSecondPairEndDate, "YYYY-MM-DD")
          .endOf("month")
          .format("YYYY-MM-DD");
      }
      const dateObj = {
        startMonthDate: startDate,
        endMonthDate: endDate,
        compareStartDate: _compareStartDate,
        compareEndDate: _compareEndDate,
      };
      setDateRange({
        ...dateRange,
        startMonthDate: startDate,
        endMonthDate: endDate,
        compareStartDate: _compareStartDate,
        compareEndDate: _compareEndDate,
      });
      if (isVisibleData) {
        onSearchClick("", true, dateObj);
      }
    }
  };

  useEffect(() => {
    manageDateRange();
  }, [dateRange.monthRange]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setLoadingMsg(Market_Country);
      const recordShipmentPayload = {
        tradeType: selectTrade?.tradeType,
        countryCode: "IND",
        country: selectTrade?.country,
        bl_flag: false,
      };
      getRecordShipmentData(recordShipmentPayload)
        .then((e) => {
          setLoading(false);
          if (e && e?.data?.length > 0) {
            const specification = e?.data[0];
            const dateObj = {
              data_start_date: specification?.data_start_date,
              data_end_date: specification?.data_end_date,
              userConstraints: e?.userConstraints?.dataAccessRange,
            };
            setExploreShipmentRecord(specification);
            manageDateRange(dateObj);
          } else {
            catchBlock();
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    }
    fetchData();
  }, []);
  const handleDateRange = (dates, dateStrings, compareDate) => {
    const start = moment(dateStrings[0]).format("YYYY-MM-DD");
    const end = moment(dateStrings[1]).format("YYYY-MM-DD");
    if (compareDate) {
      setDateRange({
        ...dateRange,
        compareStartDate: start,
        compareEndDate: end,
      });
    } else {
      setDateRange({
        ...dateRange,
        startMonthDate: start,
        endMonthDate: end,
      });
    }
  };

  const packDTParams = (isCheckedEmpty) => {
    let obj = formulateData(SHIPMENT_RESULT_TYPE_RECORDS, isCheckedEmpty);
    return obj;
  };

  const formulateData = (shipmentResultType, isCheckedEmpty) => {
    let payload = null;
    let recordsPayload = {
      tradeType: selectTrade?.tradeType.toLowerCase(),
      originCountry: selectTrade?.country.toLowerCase(),
      dateRange: {
        startDate: dateRange?.startMonthDate,
        endDate: dateRange?.endMonthDate,
        startDateTwo: dateRange?.compareStartDate,
        endDateTwo: dateRange?.compareEndDate,
      },
      valueFilterRangeFlag: selectTrade?.valueFilterRangeFlag,
      valueFilterRangeArr: [
        { from: selectTrade?.minValue, to: selectTrade?.maxValue },
      ],
      shipmentFilterRangeFlag: selectTrade?.shipmentFilterRangeFlag,
      shipmentFilterRangeArr: [
        { from: selectTrade?.minValue, to: selectTrade?.maxValue },
      ],
      hsCodeType: selectTrade?.hsCode,
      bindByPort: selectTrade?.groupBy === "PORT" ? true : false,
      bindByCountry: selectTrade?.groupBy === "COUNTRY" ? true : false,
    };
    const dividedDateRange = divideDateRange(
      dateRange?.startMonthDate,
      dateRange?.endMonthDate,
      exploreShipmentSpecification?.data_end_date
    );
    if (dividedDateRange) {
      const dateExpraession =
        getDateExpressionUsingDividedDateRangeForMarketPlace(dividedDateRange);
      recordsPayload.dateExpraession = dateExpraession;
    }
    let _CheckedData = { ...CheckedData };
    if (CheckedData && Object.keys(CheckedData)?.length) {
      for (const [key, value] of Object.entries(CheckedData)) {
        if (Object.keys(value).length === 0) {
          delete _CheckedData[key];
        }
      }
    }
    if (isCheckedEmpty) {
      _CheckedData = {};
    }
    if (shipmentResultType === SHIPMENT_RESULT_TYPE_RECORDS) {
      payload = JSON.parse(
        JSON.stringify(exploreShipmentSpecification?.explore_aggregation)
      );
    }
    if (
      exploreShipmentSpecification?.filter_field_semantic != null &&
      exploreShipmentSpecification?.filter_field_semantic?.length > 0 &&
      !isCheckedEmpty
    ) {
      let demoMatchExpression = recordsPayload?.fiterAppied ?? [];
      exploreShipmentSpecification?.filter_field_semantic?.forEach(
        (filterFieldSemantic) => {
          switch (filterFieldSemantic?.identifier) {
            case "FILTER_HS_CODE": {
              if (!removeButtonState.FILTER_HS_CODE) {
                let findClauseFilterHsCode = payload?.matchExpressions.filter(
                  (expression) => expression?.identifier === "FILTER_HS_CODE"
                )[0];

                if (_CheckedData?.HS_CODE) {
                  let field = [];
                  for (const [key, value] of Object.entries(
                    _CheckedData.HS_CODE
                  )) {
                    const newkey = key.replace(/^0+/, "");
                    field.push(newkey);
                  }
                  findClauseFilterHsCode.fieldValue = field;
                  demoMatchExpression?.push(findClauseFilterHsCode);
                  recordsPayload.fiterAppied = demoMatchExpression;
                }
              }
              break;
            }
            default:
              break;
          }
        }
      );
    }
    if (
      _CheckedData &&
      _CheckedData?.VALUE &&
      Object?.keys(_CheckedData?.VALUE)?.length > 0
    ) {
      const valueArr = [];
      Object?.keys(_CheckedData?.VALUE)?.forEach((e) => {
        let { min, max } = valueFilter.getRangeFromOption(e);
        valueArr.push({ from: min, to: max });
      });
      recordsPayload.valueFilterRangeFlag = true;
      recordsPayload.valueFilterRangeArr = valueArr;
    } else {
      if (selectTrade?.minValue === 0 && selectTrade?.maxValue === 0) {
        recordsPayload.valueFilterRangeFlag = false;
      } else {
        recordsPayload.valueFilterRangeFlag = true;
      }
    }
    if (
      _CheckedData &&
      _CheckedData?.SHIPMENT &&
      Object?.keys(_CheckedData?.SHIPMENT)?.length > 0
    ) {
      const shipmentArr = [];
      Object?.keys(_CheckedData?.SHIPMENT)?.forEach((e) => {
        let { min, max } = ShipmentFilter.getRangeFromOption(e);
        shipmentArr.push({ from: min, to: max });
      });
      recordsPayload.shipmentFilterRangeFlag = true;
      recordsPayload.shipmentFilterRangeArr = shipmentArr;
    } else {
      recordsPayload.shipmentFilterRangeFlag = false;
    }
    return recordsPayload;
  };
  const handleChangeRowsPerPage = (value) => {
    // setLoading(true);
    setLoadingMsg(Searching);
    setRowsPerPage(value);
    setPage(0);
    let recordsPayload = packDTParams();
    recordsPayload.start = 0;
    recordsPayload.length = value;
    const startRecord = 0 * value;
    const data = totalRecord.slice(startRecord, startRecord + value);
    setTableData(data);
    translateButtonClick();
    // getmarketProductRecord(recordsPayload);
  };
  //   const getmarketProductRecord = (payload) => {
  //     marketProductRecord(payload)
  //       .then((e) => {
  //         setLoading(false);
  //         if (e) {
  //           if (e && e.status === 200) {
  //             const record = e?.data;
  //             const _risonQuery = record?.risonData;
  //             setRisonQuery(
  //               _risonQuery?.date1?.substring(1, _risonQuery?.date1.length - 1)
  //             );
  //             setCompareRisonQuery(
  //               _risonQuery?.date2?.substring(1, _risonQuery?.date2?.length - 1)
  //             );
  //             setTotalAvailableData(record?.product_count);
  //             FormattedData(record?.product_data);
  //             setIsFiltersShown(true);
  //             setIsVisibleData(true);
  //           } else {
  //             throw throwError(e);
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         catchBlock(err);
  //       });
  //   };
  const handleChangePage = (newPage) => {
    // setLoading(true);
    setLoadingMsg(Searching);
    let offset = (newPage - 1) * rowsPerPage;
    if (offset + 10 > 200) {
      //   setLoading(false);
      setError(true);
      setErrorMsg(RecordLimit_200);
    } else {
      setPage(newPage - 1);
      let recordsPayload = packDTParams();
      recordsPayload.start = offset >= 0 ? offset : 0;
      recordsPayload.length = rowsPerPage;
      const startRecord = (newPage - 1) * rowsPerPage;
      const data = totalRecord.slice(startRecord, startRecord + rowsPerPage);
      setTableData(data);
      translateButtonClick();
    }
  };

  const FormattedData = (record) => {
    let title = "";
    if (selectTrade?.groupBy === "PORT") {
      title = "port_data";
    } else if (selectTrade?.groupBy === "COUNTRY") {
      title = "country_data";
    } else {
      title = "hs_code_data";
    }
    if (title === "hs_code_data") {
      const arr = [];
      record &&
        record?.length > 0 &&
        record?.forEach((ele) => {
          let obj = {
            hsCode_description: ele?.hs_Code_Description,
            hsCode: sanitizeHsCodeDigitBoundaries(
              ele.hs_code,
              selectTrade?.hsCode
            ),
            data: [],
          };
          Object.keys(ele[title]) &&
            Object.keys(ele[title])?.length > 0 &&
            Object.keys(ele[title])?.forEach((e) => {
              obj?.data?.push(ele[title][e]);
            });
          arr.push(obj);
        });
      setTotalRecord(arr);
      const startRecord = page * rowsPerPage;

      const _data = arr.slice(startRecord, rowsPerPage);
      setTableData(_data);
    } else {
      const arr = [];
      record &&
        record?.length > 0 &&
        record?.forEach((ele) => {
          ele[title] &&
            ele[title]?.length > 0 &&
            ele[title]?.forEach((e) => {
              let obj = {
                hsCode_description: ele?.hs_Code_Description,
                hsCode: ele?.hs_code,
                name: title === "port_data" ? e?.port : e?.country,
                data: [],
              };
              obj?.data?.push(e?.date1);
              obj?.data?.push(e?.date2);
              arr.push(obj);
            });
        });
      setTotalRecord(arr);
      const startRecord = page * rowsPerPage;

      const _data = arr.slice(startRecord, rowsPerPage);
      setTableData(_data);
    }
  };
  const onSearchClick = (event, monthFilter, dateRangeData) => {
    setLoading(true);
    setLoadingMsg(Searching);
    let recordsPayload = packDTParams(true);
    recordsPayload.start = 0;
    recordsPayload.length = rowsPerPage;
    if (Object.keys(CheckedData).length > 0) {
      let currentAppliedFilterData = {};
      setCheckedData({});
      setAppliedFilterData(currentAppliedFilterData);
    }
    let payload = packDTParams(true);
    if (monthFilter) {
      const dateObj = {
        startDate: dateRangeData?.startMonthDate,
        endDate: dateRangeData?.endMonthDate,
        startDateTwo: dateRangeData?.compareStartDate,
        endDateTwo: dateRangeData?.compareEndDate,
      };
      payload.dateRange = dateObj;
      recordsPayload.dateRange = dateObj;
    }
    marketProductRecordAndFilter(recordsPayload, payload)
      .then((e) => {
        setLoading(false);
        if (e[0]?.status === 202 || e?.status === 202) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Info",
            content: e[0]?.data?.message || e?.data?.message,
          });
        } else if (e?.status === 401) {
          throw throwError(e);
        } else if (e?.status === 500) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: e[0]?.data?.message || e?.data?.message,
          });
        } else {
          if (e && e?.length > 0) {
            if (e[0] && e[0].status === 200) {
              const record = e[0]?.data;
              const _risonQuery = record?.risonData;
              setRisonQuery(
                _risonQuery?.date1?.substring(1, _risonQuery?.date1.length - 1)
              );
              setCompareRisonQuery(
                _risonQuery?.date2?.substring(1, _risonQuery?.date2?.length - 1)
              );
              setTotalAvailableData(record?.product_count);
              FormattedData(record?.product_data);
              setIsFiltersShown(true);
              setIsVisibleData(true);
              translateButtonClick();
            } else {
              throw throwError(e);
            }
            if (e[1] && e[1]?.status === 200) {
              setGetAllSplitFilters(e[1]?.data);
              setAllFilterValuesWithCountsArr(e[1]?.data?.filter);
              setAllFilterValueArray(e[1]?.data);
              setFilterDataBasedOnSearch(e[1]?.data, true);
            } else {
              throw throwError(e);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  const filterOnSearchClick = (isCheckedEmpty) => {
    setLoadingMsg(Searching);
    setLoading(true);
    const _CheckedData = { ...CheckedData };
    for (const [key, value] of Object.entries(_CheckedData)) {
      if (Object.keys(value).length === 0) {
        delete _CheckedData[key];
        setCheckedData(_CheckedData);
        setStoreFilterValues(_CheckedData);
      }
    }
    let recordsPayload = packDTParams(isCheckedEmpty);
    recordsPayload.start = 0;
    recordsPayload.length = rowsPerPage;

    let payload = packDTParams(isCheckedEmpty);
    marketProductRecordAndFilter(recordsPayload, payload)
      .then((e) => {
        setLoading(false);
        if (e?.data?.message) {
          setLoading(false);
          setIsFilterLoader(false);
          setError(true);
          setErrorMsg({
            title: "Warning",
            content: e?.data?.message,
          });
        } else if (e?.status === 401) {
          throw throwError(e);
        } else {
          if (e && e?.length > 0) {
            if (e[0] && e[0]?.status === 200) {
              const record = e[0]?.data;
              FormattedData(record?.product_data);
              const _risonQuery = record?.risonData;
              setRisonQuery(
                _risonQuery?.date1?.substring(1, _risonQuery?.date1.length - 1)
              );
              setCompareRisonQuery(
                _risonQuery?.date2?.substring(1, _risonQuery?.date2?.length - 1)
              );
              setTotalAvailableData(record?.product_count);
            } else {
              throw throwError(e);
            }
            if (e[1] && e[1]?.status === 200) {
              setGetAllSplitFilters(e[1]?.data);
              setAllFilterValuesWithCountsArr(e[1]?.data?.filter);
              let filter = { ...getAllFilterValueArray };
              setFilterDataBasedOnSearch(
                filter,
                isCheckedEmpty,
                true,
                e?.data?.filter
              );
              translateButtonClick();
            } else {
              throw throwError(e);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  };
  const setFilterDataBasedOnSearch = (
    e,
    isCheckedEmpty,
    isFilter,
    filterData
  ) => {
    if (e) {
      setInitialAllFilterValues(e?.filter);
      e?.filter?.FILTER_HS_CODE &&
        e?.filter?.FILTER_HS_CODE?.length &&
        e?.filter?.FILTER_HS_CODE?.forEach((e) => {
          e._id = sanitizeHsCodeDigitBoundaries(e?._id.toString(), 8, true);
        });
      let hsCode = [];

      if (e?.filter && e?.filter?.FILTER_HS_CODE) {
        hsCode = [...e?.filter?.FILTER_HS_CODE];
      }
      filterHSCode(hsCode, isCheckedEmpty, isFilter, filterData);
      setIsFilterLoader(false);
    } else {
      setLoading(false);
      setIsFilterLoader(false);
      setError(true);
      setErrorMsg({
        title: "Error",
        content: ErrorData?.SOMETHING_WENT_WRONG,
      });
    }
  };
  const filterHSCode = (
    arrValueHsCodeData,
    isCheckedEmpty,
    isFilter,
    filterData
  ) => {
    const groupedHsCodeData = {};
    arrValueHsCodeData.forEach((item) => {
      const parentId = item?._id.toString().substring(0, 2);
      const childId = item?._id.toString().substring(0, 4);
      if (!groupedHsCodeData[parentId]) {
        groupedHsCodeData[parentId] = {
          _id: parentId,
          isChecked: false,
          children: [],
        };
      }

      const parentObj = groupedHsCodeData[parentId];

      if (!parentObj.children.find((child) => child._id === childId)) {
        parentObj?.children.push({
          _id: childId,
          isChecked: false,
          children: [],
        });
      }

      let childObj = parentObj?.children?.find(
        (child) => child._id === childId
      );
      childObj.children.push({
        _id: item?._id.toString(),
        isChecked:
          CheckedData &&
          CheckedData.hasOwnProperty("HS_CODE") &&
          Object.keys(CheckedData["HS_CODE"])?.length > 0 &&
          Object.keys(CheckedData["HS_CODE"]).includes(item?._id?.toString()) &&
          !isCheckedEmpty
            ? true
            : false,
      });
    });

    let hsCode = Object.values(groupedHsCodeData)?.map((ele) => {
      ele?.children &&
        ele?.children?.length > 0 &&
        ele?.children?.map((child) => {
          child?.children &&
            child?.children?.length > 0 &&
            child?.children?.map((subChild) => {
              return subChild;
            });
          child.isChecked = getChildValue(child?.children);
          return child;
        });
      ele.isChecked = getChildValue(ele?.children) ? true : false;
      return ele;
    });
    setPairForTreeView({
      HS_CODE: hsCode ?? [],
    });
  };

  const handleApplyFilter = (e) => {
    setApply({
      ...apply,
      [headerTitle]: true,
    });

    removeButtonState = {
      ...removeButtonState,
      [headerTitle]: false,
    };
  };

  useEffect(() => {
    let _appliedFilterData = { ...appliedFilterData };
    if (_appliedFilterData && Object.keys(_appliedFilterData)?.length) {
      delete _appliedFilterData[FILTER_OPTIONS?.VIEW_COLUMNS];
    }
    if (apply && Object.keys(apply)?.length > 0) {
      for (const [keys, values] of Object.entries(apply)) {
        if (!values) {
          delete _appliedFilterData[keys];
        }
      }
    }
    if (JSON.stringify(CheckedData) !== JSON.stringify(_appliedFilterData)) {
      setCheckedData(_appliedFilterData);
    }
  }, [apply]);

  useEffect(() => {
    if (headerTitle !== FILTER_OPTIONS?.VIEW_COLUMNS) {
      setAppliedFilterData(storeFilteredValues);
    }
  }, [storeFilteredValues]);

  if (appliedFilterData) {
    for (const [key, value] of Object.entries(appliedFilterData)) {
      for (const [keys, values] of Object.entries(value)) {
        if (!values && values !== 0) {
          const _appliedFilterData = appliedFilterData;
          delete _appliedFilterData[key][keys];
          setAppliedFilterData(_appliedFilterData);
        }
      }
    }
  }
  useEffect(() => {
    if (CheckedData && Object.keys(CheckedData).length > 0) {
      filterOnSearchClick();
    }
  }, [CheckedData]);

  const filterOptionClick = (name) => {
    switch (name) {
      case FILTER_OPTIONS?.HS_CODE:
        setHeaderTitle(FILTER_OPTIONS?.HS_CODE);
        break;
      case FILTER_OPTIONS?.VALUE:
        setHeaderTitle(FILTER_OPTIONS?.VALUE);
        break;
      case FILTER_OPTIONS?.SHIPMENT:
        setHeaderTitle(FILTER_OPTIONS?.SHIPMENT);
        break;
      default:
        break;
    }
  };
  const RemoveAppliedFilter = (index, e, val) => {
    const all = { ...storeFilteredValues };
    const _CheckedData = { ...CheckedData };
    let header = e[index][0];
    removeButtonState = {
      ...removeButtonState,
      [e[index][0]]: true,
    };
    delete _CheckedData[header];
    delete all[header];
    setStoreFilterValues(all);
    setCheckedData(_CheckedData);

    if (Object.keys(_CheckedData).length === 0) {
      filterOnSearchClick(true);
    }
    setApply({
      ...apply,
      [header]: false,
    });
  };
  const handleToggleFilters = () => {
    setIsFiltersShown(!isFiltersShown);
  };
  useEffect(() => {
    let view = "";
    if (
      (selectTrade?.tradeType === "IMPORT" ||
        selectTrade?.tradeType === "import") &&
      (selectTrade?.country === "INDIA" || selectTrade?.country === "india")
    ) {
      view = "c0b94660-ae14-11ed-a5c6-a59811e1d945";
    } else if (
      (selectTrade?.tradeType === "EXPORT" ||
        selectTrade?.tradeType === "export") &&
      (selectTrade?.country === "INDIA" || selectTrade?.country === "india")
    ) {
      view = "1edde8d0-ae11-11ed-a5c6-a59811e1d945";
    }
    setViewId(view);
  }, [selectTrade?.tradeType, selectTrade?.country]);

  useEffect(() => {
    translateButtonClick();
  }, []);
  const handleDownloadExcel = async () => {
    let recordsPayload = packDTParams();
    recordsPayload.start = (page + 1 - 1) * rowsPerPage;
    recordsPayload.length = rowsPerPage;
    setLoading(true);
    setLoadingMsg(DownLoad_Excel_Notify);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    let xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function () {
      var a;
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        // Trick for making downloadable link
        setLoading(false);
        a = document.createElement("a");
        a.href = window.URL.createObjectURL(xhttp.response);
        // Give filename you wish to download
        a.download = "data.xlsx";
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        setError(true);
        setErrorMsg(DownLoad_Excel_SuccessFull);
      } else if (xhttp.status === 500 || xhttp.status === 400) {
        setLoading(false);
        setError(true);
        setErrorMsg({
          title: xhttp?.status === 401 ? "Warning" : "Error",
          content:
            xhttp?.status === 500
              ? ErrorData?.INTERNAL_SERVER
              : xhttp?.status === 401
              ? ErrorData?.SESSION_TIMEOUT
              : ErrorData?.SOMETHING_WENT_WRONG,
        });
      }
    };
    // Post data to URL which handles post request
    xhttp.open("POST", `${urlHost}/marketanalytics/product/download `);
    xhttp.withCredentials = true;
    let val = getCookie("tokenUI");
    xhttp.setRequestHeader("Content-Type", "application/json");
    xhttp.setRequestHeader("React", true);
    xhttp.setRequestHeader("Cookies", val);
    // You should set responseType as blob for binary responses
    xhttp.responseType = "blob";
    xhttp.send(JSON.stringify(recordsPayload));
    // setloading(false);
  };

  return (
    <div id="marketProduct">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {error && (
        <ErrorLoading errorMsg={errorMsg} open={error} setOpen={setError} />
      )}
      {isRecordBtnChecked && (
        <div className="paddingAllLeftRight">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SelectComponent
              pageTitle={pageTitle}
              isMarketProduct={true}
              btnShow={true}
              btnIcon={SearchIcon}
              btnName={"Search"}
              selectTrade={selectTrade}
              handelChangeSelect={handelChangeSelect}
              btnClick={onSearchClick}
            />
            {totalAvailableData && (
              <MarketSummary totalAvailableData={totalAvailableData} />
            )}
          </div>
          {isVisibleData && (
            <div className="monthRange-container">
              <MonthTab
                dateRange={dateRange}
                setDateRange={setDateRange}
                handleDateRange={handleDateRange}
                onSearchClick={onSearchClick}
                setIsRecordBtnChecked={setIsRecordBtnChecked}
                handleDownloadExcel={handleDownloadExcel}
              />
            </div>
          )}
        </div>
      )}

      {isVisibleData && isRecordBtnChecked && (
        <div className="marketProduct-filter">
          <Grid container spacing={2}>
            <Grid
              className="marketProductFilter-div"
              item
              xs={isFiltersShown ? 2.5 : 0}
              display={isFiltersShown ? "" : "none"}
            >
              <Box width="100%" id="marketCountryFilter"></Box>
              <CollapseFilter
                isFiltersShown={isFiltersShown}
                handleApplyFilter={handleApplyFilter}
                setIsFiltersShown={setIsFiltersShown}
                filterOptionClick={filterOptionClick}
                headerTitle={headerTitle}
                keyValuePairForTreeview={keyValuePairForTreeview}
                allFilterValuesWithCountsArr={allFilterValuesWithCountsArr}
                getAllFilterValues={getAllFilterValues}
                getFilterValues={getFilterValues}
                isFromMarket={true}
                isMarketProduct={true}
                setFilterValue={setFilterValue}
                currentCountry={selectTrade?.country}
                storeFilteredValues={storeFilteredValues}
                setStoreFilterValues={setStoreFilterValues}
                CheckedData={CheckedData}
                appliedFilterData={appliedFilterData}
                setPairForTreeView={setPairForTreeView}
                isFilterLoader={isFilterLoader}
                appliedFilterComp={
                  <ExploreFilter
                    CheckedData={CheckedData}
                    isFromExplore={true}
                    RemoveAppliedFilter={RemoveAppliedFilter}
                    apply={apply}
                    quantityData={[]}
                    handleToggleFilters={handleToggleFilters}
                  />
                }
              />
            </Grid>
            {!isFiltersShown && (
              <Box className="filter-label-off-marketProduct">
                <Box
                  id="set-filters-showing-arrow-marketProduct"
                  style={{
                    zIndex: isVisibleData && !isFiltersShown ? 10000 : "",
                  }}
                  onClick={() => setIsFiltersShown(!isFiltersShown)}
                >
                  <img
                    src={Filter}
                    alt="filter"
                    width="20px"
                    height="20px"
                  ></img>

                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                    }}
                  />
                </Box>
              </Box>
            )}
            <Grid item xs={isFiltersShown ? 9.5 : 12}>
              <CompareTable
                columns={ProductTableHeader[selectTrade?.groupBy]}
                rows={tableData.sort((a, b) =>
                  a.hsCode.localeCompare(b.hsCode)
                )}
                dateRange={dateRange}
                showPagination={true}
                rowsPerPage={rowsPerPage}
                totalAvailableData={totalAvailableData || 0}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                isMarketProduct={true}
                groupBy={selectTrade?.groupBy}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {!isRecordBtnChecked && (
        <Iframe
          storeRisonQuery={risonQuery}
          fieldTerm={
            exploreShipmentSpecification?.explore_aggregation?.sortTerm
          }
          dateRange={dateRange}
          currentCountry={selectTrade?.country}
          currentTradeType={selectTrade?.tradeType}
          compareRisonQuery={compareRisonQuery}
          view={viewId}
          setIsRecordBtnChecked={setIsRecordBtnChecked}
        />
      )}
    </div>
  );
});

export default MarketProduct;
