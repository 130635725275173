import React, { useEffect, useState } from "react";
import {
  getUserDataForWorkSpace,
  getAllWorkSpaceList,
  downloadWorkspaceRecord,
  shareWorkspace,
  onSearchGetWorkspace,
  deleteWorkspace,
  getTaxonomyCountryList,
  getTaxonomyGlobal,
} from "../../Services/service";
import Loading from "../../Component/SuccessLoader/success";
import Details from "./Component/Details/details";
import FetchWorkspace from "./Component/fetchWorkspace/fetchWorkspace";
import ShareWorkspace from "./Component/shareWorkspace/share";
import ModalComponent from "../../Component/Modal/modal";
import ConfirmationModal from "../../Component/ConfirmationModal/ConfirmationModal";
import ApprovedSuccess from "../../Component/ApprovedSuccessModal/ApprovedSuccess";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Error from "../../Component/ErrorLoader/error";
import throwError from "../../utils/throwError";
import ErrorData from "../../utils/ErrorHandling";
import DeleteIcon from "../../Asset/images/delete.png";
import {
  Delete,
  DownLoad_Excel_Notify,
  DownLoad_Excel_SuccessFull,
  File_Downloading,
  PageLanding_WorkSpace,
  Retrieving_Workspaces,
  SELECT_COUNTRY,
  SELECT_TRADETYPE,
  SHARE_WORKSPACE,
  Successfully_Delete_Workspace,
  Workspaces_Absent,
} from "../../utils/LoadingMsg";
import { logger } from "../../Loggers/Loggers";

export default function WorkspaceList(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [fetchTaxonomy, setTaxonomy] = useState([]);
  const [fetchTaxonomyDetails, setTaxonomyDetails] = useState([]);
  const dataTypesOptionValues = [{ title: "IMPORT" }, { title: "EXPORT" }];
  const [selectedDataType, setSelectedDataType] = useState("");
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [userNameOptionValues, setUserNameOptionValues] = useState([]);
  const [otherUser, setOtherUser] = useState([]);
  const [allWorkspacesData, setallWorkspacesData] = useState([]);
  const [workspaceLimit, setWorkspaceLimit] = useState({});
  const [isShareModal, setIsShareModal] = useState(false);
  const [currentSelectedWorkspaceName, setCurrentSelectedWorkspaceName] =
    useState("");
  const [CurrentworkSpaceData, setCurrentWorkSpaceData] = useState([]);
  const [shareUserId, setShareUserId] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [workspaceAbsent, setWorkspaceAbsent] = useState(false);
  const [currentSelectedWorkspaceId, setCurrentSelectedWorkspaceId] =
    useState("");
  const [isDeleteRecord, setIsDeleteRecord] = useState(false);
  const [SuccessFullyComplete, setSuccessFullyComplete] = useState(false);
  const [approvSuccessModalData, setApprovSuccessModalData] = useState({});

  const setErrorForDashboard = (err) => {
    const errorForLogger = `WorkspaceList:- ${
      err?.message ? err?.message : err?.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err?.status === 401 ? "Warning" : "Error",
      content:
        err?.status === 500
          ? ErrorData?.INTERNAL_SERVER
          : err?.status === 401
          ? err?.msg
          : ErrorData?.SOMETHING_WENT_WRONG,
    });
  };
  const sortCountryWiseData = (selectedCountryData) => {
    let sortedArr = [];
    sortedArr =
      selectedCountryData &&
      selectedCountryData?.length &&
      selectedCountryData.sort(function (a, b) {
        return a?.title?.localeCompare(b?.title);
      });

    return sortedArr;
  };

  useEffect(() => {
    setLoading(true);
    setLoadingMsg(PageLanding_WorkSpace);
    const _country = [...country];
    getTaxonomyCountryList()
      .then((e) => {
        if (e?.data) {
          setTaxonomy(e.data);
          const addCountryCode = [];
          let countryList = [];
          if (e?.data && e?.data?.length > 0) {
            countryList = e?.data.filter(
              (item) => Object.keys(item).length > 0
            );
            countryList &&
              countryList?.length > 0 &&
              countryList?.forEach((item) => {
                if (!addCountryCode.includes(item?.code_iso_3)) {
                  addCountryCode.push(item?.code_iso_3);
                  _country.push({
                    title: item?.country
                      .toString()
                      .toUpperCase()
                      .trim()
                      .replace(/_/g, " "),
                    code_iso_3: item?.code_iso_3,
                  });
                }
              });
          }
          const sortedCountryArray = sortCountryWiseData(_country);
          setCountry(sortedCountryArray);
        } else {
          setLoading(false);
          setError(true);
          setErrorMsg({
            title: "Error",
            content: ErrorData.SOMETHING_WENT_WRONG,
          });
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });

    getUserDataForWorkSpace(props?.CLIENT_ACCOUNT_ID)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        }
        if (e?.data) {
          setUserData(e?.data);
          const _userNameOptionValues = [...userNameOptionValues];
          let _otherUser = [];
          e?.data &&
            e?.data?.length > 0 &&
            e?.data?.forEach((user) => {
              if (user?._id === props?.CLIENT_USER_ID) {
                const _selectedUser = {
                  userId: user?._id,
                  userName: user?.first_name + " " + user?.last_name,
                };
                setSelectedUser(_selectedUser);
              } else {
                const obj = {
                  userId: user?._id,
                  userName: user?.first_name + " " + user?.last_name,
                };
                _otherUser.push(obj);
              }
              if (
                _userNameOptionValues &&
                _userNameOptionValues.filter(
                  (e) => e?.userName === user?.first_name
                ).length === 0
              ) {
                _userNameOptionValues.push({
                  userId: user?._id,
                  userName: user?.first_name + " " + user?.last_name,
                });
              }
            });
          setUserNameOptionValues(_userNameOptionValues);
          setOtherUser(_otherUser);
          setWorkspaceList();
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  }, []);
  useEffect(() => {
    getTaxonomyGlobal()
      .then((res) => {
        setLoading(false);
        setTaxonomyDetails(res?.data);
      })
      .catch((error) => {});
  }, []);
  const setWorkspaceList = () => {
    setLoading(true);
    setLoadingMsg(Retrieving_Workspaces);
    getAllWorkSpaceList(props?.CLIENT_USER_ID)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data?.length === 0) {
            setLoadingMsg(Workspaces_Absent);
            setWorkspaceAbsent(true);
          }
          setWorkspaceLimit({
            allotedLimit: e?.allotedLimit,
            consumedLimit: e?.consumedLimit,
          });
          e.data.sort((a, b) => b?.created_ts - a?.created_ts);
          setallWorkspacesData(e?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const handleSelectedUser = (e) => {
    const fName = e?.target?.value?.split(" ")[0];
    const lName = e?.target?.value?.split(" ")[1];

    const FilterData =
      userData &&
      userData?.length > 0 &&
      userData.find((e) => e?.first_name === fName && e?.last_name === lName);

    setSelectedUser({
      userId: FilterData?._id,
      userName: FilterData?.first_name + " " + FilterData?.last_name,
    });
  };
  const handleDownload = (workspace) => {
    let paramsObj = {};
    let _taxonomyDetails = {};
    if (fetchTaxonomyDetails?.length > 0) {
      if (workspace?.country === "bl") {
        _taxonomyDetails =
          fetchTaxonomyDetails?.find(
            (e) =>
              e?.code_iso_3.toUpperCase() ===
                workspace?.code_iso_3?.toUpperCase() &&
              e?.trade?.toUpperCase() === workspace?.trade?.toUpperCase() &&
              e?.bl_flag === true
          ) || {};
      } else {
        _taxonomyDetails =
          fetchTaxonomyDetails?.find(
            (e) =>
              e?.country?.toUpperCase() === workspace?.country?.toUpperCase() &&
              e?.trade.toUpperCase() === workspace?.trade?.toUpperCase()
          ) || {};
      }
    }
    if (_taxonomyDetails && Object.keys(_taxonomyDetails)?.length > 0) {
      let newArr = [];

      _taxonomyDetails?.fields?.explore?.map((e) => {
        newArr.push(e);
      });
      _taxonomyDetails?.fields?.all?.map((e) => {
        if (!e?.includes("RECORDS_TAG")) {
          if (!newArr.includes(e)) newArr.push(e);
        }
      });
      paramsObj.allFields = newArr;
    }

    if (workspace?.country === "Mirror_table") {
      paramsObj.country =
        workspace?.country === "Mirror_table"
          ? "MirrorTable"
          : workspace.country;
      paramsObj.user_id = props?.CLIENT_USER_ID;
      paramsObj.workspace_id = workspace?._id;
      paramsObj.workspace_name = workspace.name;
      paramsObj.account_id = props.CLIENT_ACCOUNT_ID;
    } else {
      paramsObj.trade =
        workspace?.trade?.toUpperCase() === "EXIM"
          ? "Exim"
          : workspace?.trade?.toUpperCase();
      paramsObj.country =
        workspace?.country === "Silkroute" ? "SilkRoute" : workspace?.country;
      paramsObj.workspaceTaxonomyId = workspace?.taxonomy_id;
      paramsObj.workspaceBucket = workspace?.data_bucket;
      paramsObj.userId = props?.CLIENT_USER_ID;
      paramsObj.workspaceId = workspace?._id;
      paramsObj.workspaceName = workspace.name;
      paramsObj.accountId = props.CLIENT_ACCOUNT_ID;
    }

    downloadWorkspaceRecord(paramsObj)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          setLoading(false);
          if (e?.status === 202) {
            setLoading(false);
            setError(true);
            setErrorMsg({
              title: "Info",
              content:
                "Workspace Download Started...We will notify you once done !",
            });
          } else if (e?.status === 200) {
            setLoading(false);
            const url = e.data.download_url;
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = `${workspace.name}.xlsx`;
            anchor.click();
            setError(true);
            setErrorMsg(DownLoad_Excel_SuccessFull);
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };
  const handleDownlodWorkspaces = (workspace) => {
    setLoading(true);
    setLoadingMsg(File_Downloading);
    // workspace.s3_path = "";
    // if (workspace?.s3_path === undefined || workspace?.s3_path === null) {
    //   handleDownload(workspace);
    // } else {
    //   try {
    //     fetch(workspace?.s3_path)
    //       .then((res) => res.blob())
    //       .then((blob) => {
    //         const objectURL = URL.createObjectURL(blob);
    //         const link = document.createElement("a");
    //         link.href = objectURL;
    //         link.setAttribute("download", `${workspace.name}.xlsx`);
    //         document.body.appendChild(link);
    //         link.click();
    //         setLoading(false);
    //       })
    //       .catch(() => {
    //         handleDownload(workspace);
    //       });
    //   } catch (error) {
    //     handleDownload(workspace);
    //   }
    // }
    handleDownload(workspace);
  };

  const handleShareBtnClick = (workspace) => {
    setCurrentSelectedWorkspaceName(workspace.name);
    setIsShareModal(true);
    setCurrentWorkSpaceData(workspace);
  };

  const handleShareUserChange = (e) => {
    setShareUserId(e?.target?.value);
  };

  const handleShareWorkspace = () => {
    if (shareUserId && shareUserId !== "" && shareUserId.length > 0) {
      setLoading(true);
      setIsShareModal(false);
      setLoadingMsg(SHARE_WORKSPACE);
      const workspaceSharedPayload = {
        shared_user_id: shareUserId,
        workspace_data: CurrentworkSpaceData,
      };

      shareWorkspace(workspaceSharedPayload).then((e) => {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      });
    }
  };

  const onSearchClick = () => {
    if (selectedDataType === "") {
      setNoDataFound(true);
      setLoadingMsg(SELECT_TRADETYPE);
      setTimeout(() => {
        setNoDataFound(false);
      }, 1000);
      return;
    }
    if (selectedCountry === "") {
      setNoDataFound(true);
      setLoadingMsg(SELECT_COUNTRY);
      setTimeout(() => {
        setNoDataFound(false);
      }, 1000);
      return;
    }

    setLoading(true);
    setLoadingMsg(Retrieving_Workspaces);
    const filterData =
      fetchTaxonomy &&
      fetchTaxonomy?.length > 0 &&
      fetchTaxonomy.filter(
        (e) =>
          e?.country?.toUpperCase() === selectedCountry.split(" ").join("_")
      );
    const currentCountryCode = filterData[0]?.code_iso_3;

    onSearchGetWorkspace(
      selectedUser?.userId,
      selectedDataType,
      currentCountryCode
    )
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e?.data) {
            e?.data.sort((a, b) => b?.created_ts - a?.created_ts);

            setallWorkspacesData(e?.data);
            if (e?.data?.length === 0) {
              setLoading(false);
              setWorkspaceAbsent(true);
            } else {
              setWorkspaceAbsent(false);
              setTimeout(() => {
                setLoading(false);
              }, 500);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const handleAnalyzeButtonClick = (workspace) => {
    let workSpaceCountry = workspace?.country;
    if (workspace?.country === "bl") {
      const countryDetails =
        country &&
        country?.length > 0 &&
        country?.find((e) => e?.code_iso_3 === workspace?.code_iso_3);
      workSpaceCountry = countryDetails?.title;
    }
    const isBl = workspace?.country === "bl" ? true : false;
    navigate(
      `/workspace/shipments/analysis?tradeType=${workspace?.trade
        ?.toUpperCase()
        ?.trim()}&country=${workSpaceCountry?.toUpperCase()}&countryCode=${workspace?.code_iso_3
        ?.toUpperCase()
        ?.trim()}&workspaceId=${workspace?._id?.trim()}&workspaceBucket=${workspace?.data_bucket?.trim()}&workspaceName=${workspace?.name?.trim()}&startDate=${workspace?.start_date?.trim()}&endDate=${workspace?.end_date?.trim()}&blFlag=${isBl}`
    );
  };

  const handleDeleteWorkspace = () => {
    setIsDeleteRecord(false);
    setLoading(true);
    setLoadingMsg(Delete);

    deleteWorkspace(currentSelectedWorkspaceId)
      .then((e) => {
        if (e?.status === 500) {
          throw throwError(e);
        } else {
          if (e?.data) {
            if (e?.data?.msg) {
              setLoading(false);

              setSuccessFullyComplete(true);
              setApprovSuccessModalData(Successfully_Delete_Workspace);
              setTimeout(() => {
                setSuccessFullyComplete(false);

                setWorkspaceList(true);
              }, 2000);
            }
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  return (
    <Container maxWidth="xxl" className="paddingAllLeftRight">
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {noDataFound && (
        <Loading
          title={loadingMsg.title}
          content={loadingMsg.content}
          loader={"hide"}
        />
      )}
      {error && <Error open={error} setOpen={setError} errorMsg={errorMsg} />}
      <ApprovedSuccess
        Open={SuccessFullyComplete}
        title={approvSuccessModalData.title}
        footerTitle={approvSuccessModalData.footerTitle}
      />

      <ModalComponent
        open={isShareModal}
        setOpen={setIsShareModal}
        header={
          otherUser && otherUser.length > 0
            ? currentSelectedWorkspaceName
            : "Share Workspace"
        }
        modalBody={
          <ShareWorkspace
            otherUser={otherUser}
            handleShareUserChange={handleShareUserChange}
          />
        }
        width="600px"
        saveText="Share Workspace"
        saveButtonShown={otherUser && otherUser.length > 0 ? true : false}
        savedClicked={handleShareWorkspace}
        saveButtonColor="#005D91"
      />

      {/* selection componant */}
      <Details
        dataTypesOptionValues={dataTypesOptionValues}
        userNameOptionValues={userNameOptionValues}
        country={country}
        selectedDataType={selectedDataType}
        selectedCountry={selectedCountry}
        selectedUser={selectedUser}
        handleSelectedUser={handleSelectedUser}
        setSelectedDataType={setSelectedDataType}
        setSelectedCountry={setSelectedCountry}
        setSelectedUser={setSelectedUser}
        onSearchClick={onSearchClick}
      />

      {/* workspace card */}
      <FetchWorkspace
        allWorkspacesData={allWorkspacesData}
        handleDownlodWorkspaces={handleDownlodWorkspaces}
        handleShareBtnClick={handleShareBtnClick}
        handleAnalyzeButtonClick={handleAnalyzeButtonClick}
        setCurrentSelectedWorkspaceId={setCurrentSelectedWorkspaceId}
        setIsDeleteRecord={setIsDeleteRecord}
      />

      <ConfirmationModal
        open={isDeleteRecord}
        setOpen={setIsDeleteRecord}
        modalBody={
          <div
            style={{
              padding: "15px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ margin: "10px" }}>
              <img src={DeleteIcon} alt="delete" />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                style={{
                  fontSize: "22px",
                  fontWeight: 600,
                  color: "rgb(50, 58, 70)",
                }}
              >
                Are you sure you want to Delete this workspace ?
              </span>
              <div style={{ fontSize: "16px", marginTop: "20px" }}>
                You have consumed{" "}
                <strong style={{ color: "red" }} className="notranslate">
                  {workspaceLimit?.consumedLimit}
                </strong>{" "}
                and left with
                <strong style={{ color: "green" }} className="notranslate">
                  {" "}
                  {workspaceLimit?.allotedLimit - workspaceLimit?.consumedLimit}
                </strong>{" "}
                limit for delete workspaces
              </div>
            </div>
          </div>
        }
        width="600px"
        saveText="Delete"
        saveButtonShown={true}
        savedClicked={handleDeleteWorkspace}
      />
      {workspaceAbsent && (
        <>
          <div style={{ margin: "90px 0px" }}>
            <div
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
                color: "rgb(108, 117, 125)",
              }}
            >
              Workspaces Absent{" "}
            </div>
            <div
              style={{
                marginTop: "10px",
                fontSize: "18px",
                display: "flex",
                justifyContent: "center",
                color: "rgb(108, 117, 125)",
              }}
            >
              You haven't created any workspaces yet !
            </div>
          </div>
        </>
      )}
    </Container>
  );
}
