import React, { useCallback, useEffect, useState, useContext } from "react";
import { UserContext } from "../../../Component/Navbar/Navbar";
import StickyTable from "../../../Component/Table/Table";
import { ButtonComponent } from "../../../Component/ButtonComponent/Button";
import "./CompanyDetail.scss";
import ModalComponent from "../../../Component/Modal/modal";
import LinkedInInfo from "../LinkedInInfo/LinkedInInfo";
import {
  getCompanyDetailData,
  getCompanyDetails,
  getCompanyEmployeeDetails,
  handlePointDeduct,
  userRequestForCompanyData,
} from "../../../Services/service";
import { logger } from "../../../Loggers/Loggers";
import throwError from "../../../utils/throwError";
import { getCookie } from "../../../utils/CommanFunction";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ApprovedSuccess from "../../../Component/ApprovedSuccessModal/ApprovedSuccess";
import { AES, enc } from "crypto-js";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  FetchData,
  REQUESTED_FOR_COMPANY_DETAIL,
} from "../../../utils/LoadingMsg";
import ErrorLoading from "../../../Component/ErrorLoader/error";
import ErrorData from "../../../utils/ErrorHandling";
import { useLocation } from "react-router-dom";
import Loading from "../../../Component/SuccessLoader/success";

export const companyTableHeader = [
  { key: "organization_name", title: "Company Name" },
  { key: "organization_founded", title: "Year Established" },
  { key: "add", title: "Action" },
];
const optionsForExistingTemplate = [1, 2, 3];
export const CREDIT_POINT_IS_NOT_ENOUGH = {
  title: "Warning",
  content: "Credit Point is not enough for view company detail",
};

const CompanyDetail = () => {
  const search = useLocation().search;
  const location = new URLSearchParams(search).get("location");
  // const keyName = new URLSearchParams(search).get("keyName");
  const company = new URLSearchParams(search).get("company");
  const currentTradeType = new URLSearchParams(search).get("currentTradeType");
  // const countryForCompanyDetail = new URLSearchParams(search).get(
  //   "CountryForCompanyDetail"
  // );
  const recordID = new URLSearchParams(search).get("recordID");
  const CLIENT_ACCOUNT_ID = getCookie("accountId");
  // let payload = {
  //   company: company,
  //   location: countryForCompanyDetail ? [countryForCompanyDetail] : [location],
  //   clientAccountId: CLIENT_ACCOUNT_ID,
  //   start: 1,
  // };
  let payload = {
    company: company,
    clientAccountId: CLIENT_ACCOUNT_ID,
    start: 1,
  };
  const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;
  // changes
  const [companyDataForExploreEyeModal, setCompanyDataForExploreEyeModal] =
    useState([]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState({});
  const [isUniqueData, setIsUniqueData] = useState();

  const [openAvailableCreditModal, setOpenAvailableCreditModal] =
    useState(false);
  const [companyDetailPage, setCompanyDetailPage] = useState(false);
  const [companyDetailPageData, setCompanyDetailPageData] = useState();
  const [rowData, setRowData] = useState();
  const [companyEmployeeData, setCompanyEmployeeData] = useState();
  const [noDataFound, setNoDataFound] = useState(false);
  const [requestForData, setRequestForData] = useState(false);
  const [approveSuccessModal, setApproveSuccessModal] = useState(false);
  const [approveSuccessModalData, setApproveSuccessModalData] = useState({});
  const [requestedData, setRequestedData] = useState(null);
  const [llmEmployeeData, setLLMEmployeeData] = useState();
  const { setProgressBarValue, setIsFromCompanyDetailPage, progressBarValue } =
    useContext(UserContext);
  const [llmDataState, setLlmDataState] = useState(false);

  const replacedLLMDataIntoObj = (data) => {
    let inputString = data;

    const match = inputString.match(/(\{.*\}|\[.*\])/s);
    if (match) {
      const extractedData = match[0];
      const parsedData = JSON.parse(extractedData);
      return [parsedData];
    }
  };
  const matchCompanyWordsForLLm = (llmCompanyName, ClickedCompanyName) => {
    if (llmCompanyName && ClickedCompanyName) {
      const companyWords = ClickedCompanyName.split(/\s+/);
      const llmCompanyWords = llmCompanyName.split(/\s+/);

      const regex = new RegExp(companyWords.join("|"), "i");
      const isMatch = llmCompanyWords.some((word) => regex.test(word));

      return isMatch;
    }
  };
  let llmDataError = false;
  useEffect(() => {
    setLoading(true);
    setLoadingMsg(FetchData);
    getCompanyDetailData(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          if (res?.data?.is_unique == true) {
            setIsUniqueData(res?.data);
            llmDataError = true;
            setLlmDataState(true);
            const data = replacedLLMDataIntoObj(
              res?.data?.response?.search_llm
            );
            if (!matchCompanyWordsForLLm(data[0]?.organization_name, company)) {
              catchBlock();
            }
            setCompanyDataForExploreEyeModal(data.flat());
          } else {
            setIsUniqueData();
            llmDataError = false;
            setLlmDataState(false);
            if (res?.data && res?.data?.response) {
              setCompanyDataForExploreEyeModal(res?.data?.response);
            }
          }
        } else {
          throw throwError(res);
        }
      })
      .catch((err) => {
        catchBlock(err);
      });
  }, []);

  useEffect(() => {
    setIsFromCompanyDetailPage(true);
    if (companyDataForExploreEyeModal?.length <= 0) {
      setNoDataFound(true);
    } else {
      setNoDataFound(false);
    }
  }, [companyDataForExploreEyeModal]);

  const [formData, setFormData] = useState({
    companyName: "",
    country: "",
    employeeData: "",
  });
  const [errors, setErrors] = useState({
    companyName: "",
    country: "",
    employeeData: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validate = () => {
    let valid = true;
    let newErrors = {
      companyName: "",
      country: "",
      employeeData: "",
    };

    if (!formData.companyName) {
      newErrors.companyName = "Company Name is required";
      valid = false;
    }

    if (!formData.country) {
      newErrors.country = "Country is required";
      valid = false;
    }

    if (!formData.employeeData) {
      newErrors.employeeData = "Employee Data is required";
      valid = false;
    } else if (isNaN(formData.employeeData)) {
      newErrors.employeeData = "Employee Data must be a number";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const catchBlock = (err) => {
    if (llmDataError) {
      setError(true);
      setErrorMsg({
        title: "Info",
        content: "Can't retrieve at this moment",
      });
    } else {
      logger(err);
      setLoading(false);
      setError(true);
      setErrorMsg({
        title: err?.status === 401 ? "Warning" : "Error",
        content:
          err?.status === 500
            ? ErrorData?.INTERNAL_SERVER
            : err?.status === 401
            ? ErrorData?.SESSION_TIMEOUT
            : ErrorData?.SOMETHING_WENT_WRONG,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      let payload = {
        company_name: formData?.companyName,
        company_location: formData?.country,
        requested_contacts: formData?.employeeData,
        user_id: getCookie("user_id"),
        account_id: getCookie("accountId"),
        first_name: getCookie("firstName"),
        last_name: getCookie("lastName"),
        email: getCookie("email_id"),
        record_id: recordID,
        country: location,
        trade: currentTradeType.toUpperCase(),
      };
      userRequestForCompanyData(payload)
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.res && res?.data?.res.length > 0) {
              setRequestedData(res?.data?.res);
            }
          } else {
            throw throwError();
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
      setFormData({
        companyName: "",
        country: "",
        employeeData: "",
      });
      setApproveSuccessModal(true);
      setApproveSuccessModalData(REQUESTED_FOR_COMPANY_DETAIL);
      setRequestForData(false);
      setTimeout(() => {
        setApproveSuccessModal(false);
      }, 2000);
    }
  };

  const handleRemainingCreditPoint = (column, row) => {
    setRowData(row);
    setOpenAvailableCreditModal(true);
  };

  const RemainingCreditPointBody = useCallback(() => {
    return (
      <div className="remaning">
        <p>
          You can View <b>{rowData?.organization_name}</b> with
          <span style={{ color: "red" }}> 1 </span> credit.
        </p>
        <p>
          Press <b>Confirm</b> to access the information.
        </p>
        <p>
          Credits available :{" "}
          <span style={{ color: "green" }}> {progressBarValue} </span>
        </p>
      </div>
    );
  }, [progressBarValue, rowData]);

  const getCompanyDetailAndEmployeeDetail = async () => {
    let payload = {
      company_domain: rowData?.organization_doamin,
      userId: getCookie("user_id"),
      company_id: [rowData?.organization_id],
      accountId: getCookie("accountId"),
    };
    const [companyDetail, employeeDetail] = await Promise.all([
      getCompanyDetails(payload),
      getCompanyEmployeeDetails(payload),
    ]);

    return {
      companyDetail: companyDetail,
      employeeDetail: employeeDetail,
    };
  };

  function removeBackticks(response) {
    const data = response.replace(/```/g, "");
    const parsedData = JSON.parse(data);
    return parsedData;
  }

  const handleFullCompanyDataShow = async () => {
    const encryptedCompanyCredits = JSON.parse(
      localStorage.getItem("company_detail_credit")
    );
    const decryptedCreditPoints = AES.decrypt(
      encryptedCompanyCredits,
      secretKey
    ).toString(enc.Utf8);
    let payload = {
      accountId: getCookie("accountId"),
      points: 1,
    };
    if (decryptedCreditPoints > 0) {
      handlePointDeduct(payload)
        .then((res) => {
          if (res?.status === 200) {
            const updatedCompanyCredit = res?.data?.companyCredits;

            if (updatedCompanyCredit) {
              setProgressBarValue(updatedCompanyCredit ?? 0);
              const encryptedCompanyCredits = AES.encrypt(
                updatedCompanyCredit?.toString(),
                secretKey
              ).toString();
              localStorage.setItem(
                "company_detail_credit",
                JSON.stringify(encryptedCompanyCredits)
              );

              if (updatedCompanyCredit > 0) {
                setLoading(true);
                if (isUniqueData?.is_unique) {
                  const data = replacedLLMDataIntoObj(
                    isUniqueData?.response?.search_llm
                  );
                  const linkedInData = removeBackticks(
                    isUniqueData?.response?.linkedin
                  );
                  setCompanyDetailPageData(data.flat()[0]);

                  setLLMEmployeeData(linkedInData);
                  setLoading(false);
                } else {
                  setLLMEmployeeData();
                  getCompanyDetailAndEmployeeDetail()
                    .then((res) => {
                      if (res?.companyDetail?.status === 200) {
                        if (
                          res?.companyDetail?.data &&
                          res?.companyDetail?.data?.res[0]
                        ) {
                          setCompanyDetailPageData(
                            res?.companyDetail?.data?.res[0]
                          );
                        } else {
                          setCompanyDetailPageData();
                        }
                        if (res?.employeeDetail?.data?.res) {
                          setCompanyEmployeeData(
                            res?.employeeDetail?.data?.res
                          );
                        }
                        setLoading(false);
                      } else {
                        throw throwError();
                      }
                    })
                    .catch((err) => {
                      catchBlock(err);
                    });
                }

                setCompanyDetailPage(true);
              }
            }
          } else {
            throw throwError();
          }
        })
        .catch((err) => {
          catchBlock(err);
        });
    } else {
      setError(true);
      setErrorMsg(CREDIT_POINT_IS_NOT_ENOUGH);
    }

    setOpenAvailableCreditModal(false);
  };

  const handleChangeForEmployeeCount = (event) => {
    const { value } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      employeeData: "",
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      employeeData: value,
    }));
  };

  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     companyName: company,
  //     country:
  //       (keyName === "IMPORTER" && currentTradeType === "IMPORT") ||
  //       (keyName === "EXPORTER" && currentTradeType === "EXPORT")
  //         ? location
  //         : countryForCompanyDetail,
  //   });
  // }, [noDataFound, countryForCompanyDetail, requestForData, keyName, company]);

  useEffect(() => {
    setFormData({
      ...formData,
      companyName: company,
      country: location,
    });
  }, [noDataFound, requestForData, company, location]);

  const requestDataForm = () => {
    return (
      <>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: 600,
            mx: "auto",
          }}
        >
          <TextField
            label="Company Name"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            error={!!errors.companyName}
            helperText={errors.companyName}
            fullWidth
          />
          <TextField
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            error={!!errors.country}
            helperText={errors.country}
            fullWidth
          />
          <FormControl className="ml-10" error={!!errors.employeeData}>
            <InputLabel id="demo-select-small">
              Select Number of Employee
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Number of Employee"
              style={{ height: "56px" }}
              onChange={handleChangeForEmployeeCount}
              value={formData.employeeData}
              key="employeeData"
            >
              {optionsForExistingTemplate.length > 0 &&
                optionsForExistingTemplate.map((e, index) => (
                  <MenuItem value={e} key={index}>
                    {e}
                  </MenuItem>
                ))}
            </Select>
            {errors.employeeData && (
              <FormHelperText>{errors.employeeData}</FormHelperText>
            )}
          </FormControl>
          <ButtonComponent name="submit" btnClick={handleSubmit} />
        </Box>
      </>
    );
  };

  const linkedInPage = useCallback(() => {
    return (
      <LinkedInInfo
        backButton={setCompanyDetailPage}
        companyDetailPageData={companyDetailPageData}
        companyEmployeeData={
          isUniqueData?.is_unique ? llmEmployeeData : companyEmployeeData
        }
        rowData={rowData}
        isUniqueData={isUniqueData}
        llmEmployeeData={llmEmployeeData}
        setLLMEmployeeData={setLLMEmployeeData}
        // countryForCompanyDetail={countryForCompanyDetail}
        CLIENT_ACCOUNT_ID={CLIENT_ACCOUNT_ID}
        currentCountry={location}
      />
    );
  }, [
    companyDetailPageData,
    companyEmployeeData,
    rowData,
    isUniqueData,
    llmEmployeeData,
  ]);
  const callbackError = useCallback(() => {
    if (error) {
      return (
        <ErrorLoading
          errorMsg={errorMsg}
          open={error}
          setOpen={setError}
          llmDataPopUp={llmDataState ?? llmDataError}
          setRequestForData={setRequestForData}
        />
      );
    }
  }, [llmDataError, error, errorMsg, llmDataState]);

  return (
    <>
      {/* {error && (
        <ErrorLoading
          errorMsg={errorMsg}
          open={error}
          setOpen={setError}
          llmDataPopUp={llmDataError}
          setRequestForData={setRequestForData}
        />
      )} */}
      {callbackError()}
      {loading && (
        <Loading title={loadingMsg.title} content={loadingMsg.content} />
      )}
      {!companyDetailPage && (
        <div className="company-detail-page-container">
          <div
            className="title-div-training"
            style={{
              padding: "20px 0px",
            }}
          >
            <h4
              className="text set-explore-title"
              style={{ textAlign: "left" }}
            >
              <span style={{ fontSize: "23px" }}>Company </span>
              <span
                className="badge bg-soft-blue text-blue label-worksapce-name"
                style={{ height: "27px" }}
              >
                Detail
              </span>
            </h4>
          </div>
          <div style={{ textAlign: "right", marginBottom: "10px" }}>
            <ButtonComponent
              // btnIcon={""}
              name="Request for data"
              btnClick={() => setRequestForData(true)}
            />
          </div>
          <StickyTable
            isFromExploreContactCompanyModal={true}
            columns={companyTableHeader}
            rows={companyDataForExploreEyeModal}
            handleCollapseOpenModal={handleRemainingCreditPoint}
            height={"100%"}
          />
          {noDataFound && !loading && (
            <div
              style={{
                fontSize: "30px",
                display: "block",
                textAlign: "center",
                color: "rgb(108, 117, 125)",
                marginTop: "20px",
              }}
            >
              <div>
                <b style={{ color: "red", textAlign: "center" }}>
                  No Data Found !
                </b>
              </div>
              <br />
              <div style={{ color: "#005d91" }}>
                If you're looking for Company Detail Data then click on 'Request
                For Data' to submit a data request.
              </div>
            </div>
          )}
        </div>
      )}

      <ModalComponent
        open={openAvailableCreditModal || requestForData}
        setOpen={
          !requestForData ? setOpenAvailableCreditModal : setRequestForData
        }
        header={
          !requestForData ? "Credits Reminder" : "Request For Company Data"
        }
        modalBody={
          !requestForData ? RemainingCreditPointBody() : requestDataForm()
        }
        saveButtonShown={!requestForData ? true : false}
        saveText="confirm"
        width={!requestForData ? "25%" : "40%"}
        savedClicked={handleFullCompanyDataShow}
        companyData={!requestForData ? true : false}
        requestForDataModal={true}
      />
      {approveSuccessModal && (
        <ApprovedSuccess
          Open={approveSuccessModal}
          title={approveSuccessModalData.title}
        />
      )}
      {companyDetailPage && linkedInPage()}
    </>
  );
};

export default CompanyDetail;
