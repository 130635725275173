import React, { createContext, useContext, useEffect, useState } from "react";
import { getPlanDetail } from "../Services/service";
import { getCookie } from "../utils/CommanFunction";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isPlanExpire, setIsPlanExpire] = useState({
    isActive: true,
    isExtended: true,
  });

  useEffect(() => {
    const accountId = getCookie("accountId");
    if (accountId) {
      getPlanDetail()
        .then((res) => {
          if (res) {
            setIsPlanExpire({
              isActive: res?.isactive,
              isExtended: res?.isextended,
            });
          }
        })
        .catch((err) => {
          console.log("err contect ", err);
        });
    }
  }, []);

  return (
    <AppContext.Provider value={{ isPlanExpire, setIsPlanExpire }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
