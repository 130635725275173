export const LOADING = {
  title: "Loading...",
  content: "Please Wait...",
};
export const PageLanding_Explore_shipment = {
  title: "Configuring Environment For Exploration...",
  content: "Preparing Data-Points For Shipments",
};

export const Searching = {
  title: "Searching...",
  content: "Please Wait...",
};

export const RecordLimit = {
  title: "Warning",
  content: "You can see only 1000 records",
};
export const RecordLimit_200 = {
  title: "Warning",
  content: "You can see only 200 records",
};
export const FetchData = {
  title: "Fetching Data...",
  content: "Please Wait...",
};

export const ApprovSuccess_viewColumns = {
  title: "Successfully View Columns Add!",
  footerTitle: "Add Successfully",
};

export const Update_Query = {
  title: "Update Query...",
  content: "Please Wait...",
};
export const Update_Query_Successfully = {
  title: "Successfully Update Query!",
  footerTitle: "Updated Successfully",
};

export const Verifying_Workspace_Availability = {
  title: "Verifying Workspace Availability",
  content: "Check if workspace with similar name exists",
};

export const Approving_Purchase = {
  title: "Approving Purchase...",
  content: "Estimating requisite credits for purchasing new shipments",
};

export const Preparing_workspace = {
  title: "Preparing your workspace",
  content: "Ingesting Records to the selected workspace",
};
export const Retrieving_Workspaces = {
  title: "Retrieving Workspaces...",
  content: "Preparing Data-Points For Workspaces",
};

export const Select_Workspace = {
  label: "Select Workspace",
  option: "Select Workspace",
};

export const Analyzing_data = {
  title: "Analyzing data...",
  content: "Please Wait...",
};

export const Saved_Query_Successfully = {
  title: "Successfully Saved Query!",
  footerTitle: "Saved Successfully",
};

export const Saved_Query = {
  title: "Saved Query...",
  content: "Please Wait...",
};

export const Request_Submitted_Successfully = {
  title: "Request Submitted Successfully!",
  footerTitle: "Submitted Successfully",
};

export const Fetching_Summary_Data = {
  title: "Fetching Summary Data...",
  content: "Please Wait...",
};

export const Retrieving_Users = {
  title: "Retrieving Users...",
  content: "Preparing State...",
};
export const Add_User = {
  title: "Adding User Details...",
  content: "Mapping Account Specifications",
};
export const Delete_User = {
  title: "Deleting User Details...",
  content: "Mapping Account Specifications",
};
export const Add_User_Credit = {
  title: "Adding User Credit...",
  content: "Mapping Account Specifications",
};

export const Delete = {
  title: "Deleting...",
  content: "Please Wait...",
};

export const Successfully_Delete_Workspace = {
  title: "Successfully Delete Workspace!",
  footerTitle: "Deleted Successfully",
};

export const SHARE_WORKSPACE = {
  title: "Sharing Workspace...",
  content: "Please Wait...",
};

export const SELECT_TRADETYPE = {
  title: "Please Select any TradeType",
  content: "",
};
export const SELECT_COUNTRY = {
  title: "Please Select any Country",
  content: "",
};
export const Workspaces_Absent = {
  title: "Workspaces Absent",
  content: "You haven't created any workspaces yet!",
};
export const PageLanding_WorkSpace = {
  title: "Configuring Environment For Workspaces",
  content: "Preparing Workspace Factors",
};
export const File_Downloading = {
  title: "Downloading...",
  content: "Your file will be downloaded soon",
};

export const Fav_company_absent = {
  title: "Favourite Company Absent",
  content: "You haven't added any company as Favourite yet!",
};

export const Fav_shipment_absent = {
  title: "Favourite Shipment Absent",
  content: "You haven't added any record as Favourite yet!",
};

export const Analytics_Unavailable = {
  title: "Analytics Unavailable",
  content: "No Correlation Available",
};

export const DownLoad_Excel_Notify = {
  title: "Downloading...",
  content:
    "Your file will be downloaded soon, you will be notified after download Successfully",
};

export const DownLoad_Excel_SuccessFull = {
  title: "Success",
  content: "Your excel data is downloaded successfully",
};

export const Market_Country = {
  title: "Configuring Environment For Market Country...",
  content: "Preparing Data-Points For Market Country",
};

export const not_access_country = {
  title: "Access Denied",
  content: "You Have Not Access This Country ! Please Upgrade Your Plan",
};

export const Retrieving_Profile = {
  title: "Retrieving Profile...",
  content: "Preparing Information",
};
export const Update_User = {
  title: "Updating User Details...",
  content: "Mapping Account Specifications",
};
export const Successfully_update_user = {
  title: "Successfully Update User Details!",
  footerTitle: "Updated Successfully",
};
export const Successfully_update_user_info = {
  title: "Info",
  content: "User Details Updated Successfully , Please Login Again",
};

export const Company_Update = {
  title: "Updating Company Details",
  content: "Mapping Account Specifications",
};
export const Company_Update_Successfully = {
  title: "Successfully Update Company Details!",
  footerTitle: "Updated Successfully",
};
export const Fetching_Requested_Buyers = {
  title: "Fetching Requested Buyers...",
  content: "Please Wait..",
};

export const Retrieving_Dashboard = {
  title: "Retrieving Dashboard...",
  content: "Preparing State",
};
export const Retrieving_Fav_Company = {
  title: "Retrieving Favourite Company...",
  content: "Preparing State",
};
export const Retrieving_Fav_Shipments = {
  title: "Retrieving Favourite Shipments...",
  content: "Preparing State",
};
export const Retrieving_Countries = {
  title: "Retrieving Countries...",
  content: "Preparing Country-wise Trade EXIM Stats",
};
export const explore_record_limits = {
  title: "Warning",
  content: "More than 4Lakhs records , please optimize your search.",
};
export const FetchDashBoard = {
  title: "Fetching DashBoard...",
  content: "Please Wait...",
};
export const VideoFeedback = {
  title: "We Got Your response!",
  footerTitle: "Add Successfully ",
};

export const not_access_mirrorData = {
  title: "Access Denied",
  content:
    "You don't have access of Mirror Data, Please contact info@eximpedia.app",
};
export const RemovingDuplicateRecord = {
  title: "Removing Duplicate Records",
};
export const hsCodeSelectLimit = {
  title: "Info",
  content: "You can only select 5 HS CODE of 2 Digit",
};
export const viewSummaryLimit = {
  title: "Warning",
  content: "Out of search for the day , please contact administrator.",
};
export const planExpiredWarning = {
  title: "Warning",
  content: "Your plan has expired, You can't access this!",
};
export const SearchRequireWarning = {
  title: "Warning",
  content: "Please Select At Least One Search Term Before Applying Filter!",
};
export const not_access_silkRoute = {
  title: "Access Denied",
  content:
    "You don't have access of SilkRoute, Please contact info@eximpedia.app",
};
export const ticket_form_validation = {
  title: "Warning",
  content: "Please Select Type,Sub-Type of Ticket and Priority ",
};
export const REQUESTED_FOR_COMPANY_DETAIL = {
  title: "Your Request Submitted Successfully",
  footerTitle: "Your Request Submitted Successfully ",
};

export const NO_ACCESS_MIRROR_FOR_CHILD = {
  title: "Access Denied",
  content:
    "You don't have access of Mirror Data, Please contact Your Admin User",
};
