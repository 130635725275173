import React, { useEffect, useRef, useState } from "react";
import "./DashboardTables.scss";
import { Tooltip } from "@mui/material";
import { Delete, planExpiredWarning } from "../../../../utils/LoadingMsg";
import {
  deleteSaveQueryData,
  favCompanyAnalysis,
  getBuyerSellerData,
  getCompetitorsData,
  getGroupExpressionOfCountry,
  getPotentialMarketData,
  getRecentSearches,
  getSaveQuery,
  getSavedQuery,
  getTaxonomyCountryList,
  searchAnalysis,
} from "../../../../Services/service";
import StickyTable from "../../../../Component/StickyTable/Table";
import throwError from "../../../../utils/throwError";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ErrorData from "../../../../utils/ErrorHandling";
import moment from "moment";
import { useNavigate } from "react-router";
import RecentSearchedCountryCard from "../RecentlySearchedCountryCard/RecentlySearchedCountryCard";
import FavouriteCompanyTable from "../../../../Component/FavouriteCompanyTable/FavouriteCompanyTable";
import { DATEFORMAT } from "../../../ExploreCountryShipmentNewUI/Explore.enum";
import {
  COMPETITORS_TABLE_HEADER,
  POTENTIAL_TABLE_HEADER,
  RECOMMENDED_TABLE_HEADER,
  TABLE_HEADER_RECENT_ACTIVITY,
} from "../../enum/DashboardNew.enum";
import ConfirmationModal from "../../../../Component/ConfirmationModal/ConfirmationModal";
import DeleteIcon from "../../../../Asset/images/delete.png";
import { AES, enc } from "crypto-js";
import { logger } from "../../../../Loggers/Loggers";
import SaveQueryCard from "../SaveQueryCard/SaveQueryCard";
import { getCookie } from "../../../../utils/CommanFunction";
import DataTable from "../../../../Component/Table/Table";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import VideoSvg from "../../../Training/Component/VideoIndexSvg/VideoSvg";
import MyAreaChart from "../AreaChart/MyAreaChart";
import Error from "../../../../Component/ErrorLoader/error";
import SearchAnalysisChart from "../SearchAnalysisChart/SearchAnalysisChart";
import { useAppContext } from "../../../../Contexts/AppContext";

var row1 = [];
var arr = [];
const DashboardTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [getQuery, setQuery] = useState([]);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [getIndex, setIndex] = useState("");
  const [recentUserActivityQuery, setRecentUserActivityQuery] = useState([]);
  const [dataTableRowValues, setDataTableRowValues] = useState([]);
  const [countryListData, setCountryListData] = useState([]);
  const countryListRef = useRef([]);
  const [recentSearchData, setRecentSearchData] = useState(null);
  const [topFiveRecentCountrySearch, setTopFiveRecentCountrySearch] = useState(
    []
  );
  const [potentialMarketData, setPotentialMarketData] = useState([]);
  // const [
  //   groupExpressionOfSelectedCountry,
  //   setGroupExpressionOfSelectedCountry,
  // ] = useState([]);
  const [recommendBuyerSeller, setRecommendBuyerSeller] = useState([]);
  const [competitorsData, setCompetitorsData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [favCompanyChartData, setFavCompanyChartData] = useState([]);
  const [searchAnalysisChartData, setSearchAnalysisChartData] = useState([]);
  const [noDataMsg, setNoDataMsg] = useState("No Data Found");
  const isPlanExpireRef = useRef({});
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const { isPlanExpire: isPlanExpireData } = useAppContext();

  const secretKey = process.env.REACT_APP_CRYPTO_JS_KEY;

  let isPlanExpire = {};
  const navigate = useNavigate();
  useEffect(() => {
    setNoDataFound(false);
    // const encryptedIsActive = JSON.parse(localStorage.getItem("isActive"));
    // const encryptedIsExtended = JSON.parse(localStorage.getItem("isExtended"));
    // if (encryptedIsActive && encryptedIsExtended) {
    //   const decryptedIsActive = AES.decrypt(
    //     encryptedIsActive,
    //     secretKey
    //   ).toString(enc.Utf8);
    //   const decryptedIsExtended = AES.decrypt(
    //     encryptedIsExtended,
    //     secretKey
    //   ).toString(enc.Utf8);
    //   isPlanExpireRef.current = {
    //     isActive: decryptedIsActive ?? false,
    //     isExtended: decryptedIsExtended ?? false,
    //   };
    // }
    isPlanExpireRef.current = {
      isActive: isPlanExpireData.isActive ?? false,
      isExtended: isPlanExpireData.isExpire ?? false,
    };
    getCountryList();
  }, [isPlanExpireData]);

  useEffect(() => {
    setLoading(true);
    setNoDataFound(false);
    const endDate = moment().format(DATEFORMAT?.format);
    const startDate = moment().subtract(1, "months").format(DATEFORMAT?.format);
    const payload = {
      userId: props.CLIENT_USER_ID,
      date_from: startDate,
      date_to: endDate,
      emails: [],
      isWorkspaceQuery: false,
      start: 0,
      length: 100000,
    };
    const handleRecentSearches = async () => {
      try {
        const res = await getRecentSearches(payload);
        if (res?.status === 200) {
          const data = res?.data || [];
          setLoading(false);
          setRecentSearchData(data);

          if (data.length > 0) {
            const recentActivityQueries = [];
            const timeRangeArr = [];
            const processedData = data.slice(0, 10).map((e) => {
              const analyseArr = [];
              const queryData = JSON.parse(e.query || "[]");

              queryData.forEach((ele) => {
                if (
                  typeof ele.fieldValue === "string" ||
                  (typeof ele.fieldValue === "object" &&
                    Object.keys(ele.fieldValue).length > 0)
                ) {
                  analyseArr.push(ele);
                }
                if (ele.identifier === "SEARCH_MONTH_RANGE") {
                  timeRangeArr.push(ele);
                }
              });

              recentActivityQueries.push(e.query);
              e.query = analyseArr;
              return e;
            });

            setRecentUserActivityQuery((prevQueries) => [
              ...prevQueries,
              ...recentActivityQueries,
            ]);
            setDataTableRowValues(processedData);
            setNoDataFound(false);
          } else {
            setNoDataMsg("You haven't searched for any trade data yet");
            setNoDataFound(true);
          }
        } else {
          throw throwError(res);
        }
      } catch (err) {
        setErrorForDashboard(err);
      }
    };

    if (
      props?.sidebarContent["RECENT SEARCHES"] ||
      props?.sidebarContent["RECENT COUNTRY SEARCH"]
    ) {
      handleRecentSearches();
    }
  }, [props?.sidebarContent]);

  useEffect(() => {
    let payload = {
      user_id: getCookie("user_id"),
    };
    props?.sidebarContent["SAVE QUERY"] && getSavedQueryData();
    props?.sidebarContent["RECOMMEND BUYERS/SELLERS"] &&
      getRecommendBuyerSeller(payload);
    props?.sidebarContent?.COMPETITORS && getCompetitorsDataList(payload);
    props?.sidebarContent["POTENTIAL MARKET"] &&
      getPotentialMarketDataList(payload);
    props?.sidebarContent["FAVORITE COMPANY"] &&
      getFavCompanyAnalysisChartData(payload);
    props?.sidebarContent["RECENT SEARCHES"] &&
      getSearchAnalysisChartData(payload);
  }, [props?.sidebarContent]);

  const getRecommendBuyerSeller = (payload) => {
    setLoading(true);
    setIsDataUpdated(false);
    getBuyerSellerData(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          if (res?.data?.res?.data) {
            if (res?.data?.res?.data?.length > 0) {
              setNoDataFound(false);
            } else {
              setNoDataFound(true);
              setNoDataMsg("No Recommendation Data Found");
            }
            setRecommendBuyerSeller(res?.data?.res?.data);
            setIsDataUpdated(true);
          }
        } else {
          setIsDataUpdated(false);
          throw throwError(res);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const exploreCompanySearch = (searchData) => {
    if (
      (props?.isPlanExpire?.isActive === true ||
        props?.isPlanExpire?.isActive === "true") &&
      (props?.isPlanExpire?.isExtended === false ||
        props?.isPlanExpire?.isExtended === "false")
    ) {
      let countryCode = countryListRef.current?.find(
        (item) =>
          item?.country?.toLowerCase() == searchData?.Country?.toLowerCase()
      );
      navigate(
        `/countries/shipments/explorer?tradeType=${searchData?.Trade}&countryCode=${countryCode?.code_iso_3}&country=${searchData?.Country}&valueSearch=${searchData?.COMPANY}&searchOn=${searchData?.Equivalent}&blFlag=false&isFromDashboard=true`
      );
    } else {
      setError(true);
      setErrorMsg({
        title: planExpiredWarning?.title,
        content: planExpiredWarning?.content,
      });
    }
  };

  // useEffect(() => {
  //   if (dataTableRowValues && dataTableRowValues?.length > 0) {
  //     getGroupExpression();
  //   }
  // }, [dataTableRowValues]);

  // const getGroupExpression = () => {
  //   let payload = {
  //     country:
  //       dataTableRowValues[0]?.country.charAt(0).toUpperCase() +
  //       dataTableRowValues[0]?.country?.slice(1).toLowerCase(),
  //     trade: dataTableRowValues[0]?.tradeType,
  //   };
  //   getGroupExpressionOfCountry(payload)
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         if (res?.data) {
  //           setGroupExpressionOfSelectedCountry(
  //             res?.data?.groupexpression[0]?.fields?.explore_aggregation
  //               ?.groupExpressions
  //           );
  //         }
  //       } else {
  //         throw throwError(res);
  //       }
  //     })
  //     .catch((err) => {
  //       setErrorForDashboard(err);
  //     });
  // };

  // useEffect(() => {
  //   if (
  //     groupExpressionOfSelectedCountry &&
  //     groupExpressionOfSelectedCountry?.length > 0
  //   ) {
  //   }
  // }, [groupExpressionOfSelectedCountry]);

  // const getPayloadRelatedData = () => {
  //   let payload = [];

  //   const tradeType = dataTableRowValues[0]?.tradeType;
  //   const identifier =
  //     tradeType === "IMPORT" ? "SUMMARY_BUYERS" : "SUMMARY_SELLERS";

  //   const identifierData =
  //     groupExpressionOfSelectedCountry?.filter(
  //       (item) => item?.identifier === identifier
  //     ) || [];

  //   const totalValueUseData =
  //     groupExpressionOfSelectedCountry?.filter(
  //       (item) => item?.identifier === "FILTER_CURRENCY_PRICE_USD"
  //     ) || [];

  //   payload = payload.concat(identifierData, totalValueUseData);
  //   payload.push(JSON.parse(recentUserActivityQuery[0]));

  //   // for (const query of recentUserActivityQuery?.slice(0, 2) || []) {
  //   //   payload.push(JSON.parse(query));
  //   // }
  //   return payload;
  // };

  const getPotentialMarketDataList = (payload) => {
    setLoading(true);
    setIsDataUpdated(false);
    getPotentialMarketData(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          if (res?.data?.res?.data?.length > 0) {
            setNoDataFound(false);
          } else {
            setNoDataFound(true);
            setNoDataMsg("No Potential Market Data Found");
          }
          setPotentialMarketData(res?.data?.res?.data);
          setIsDataUpdated(true);
        } else {
          setIsDataUpdated(false);
          throw throwError(res);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const getFavCompanyAnalysisChartData = (payload) => {
    setLoading(true);
    favCompanyAnalysis(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setFavCompanyChartData(res?.data?.res);
        } else {
          throw throwError(res);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const getSearchAnalysisChartData = (payload) => {
    setLoading(true);
    searchAnalysis(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setSearchAnalysisChartData(res?.data);
        } else {
          throw throwError(res);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const getCompetitorsDataList = (payload) => {
    setIsDataUpdated(false);
    setLoading(true);
    getCompetitorsData(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          if (res?.data?.res?.data?.length > 0) {
            setNoDataFound(false);
          } else {
            setNoDataFound(true);
            setNoDataMsg("No Competitors Found");
          }
          setCompetitorsData(res?.data?.res?.data);
          setIsDataUpdated(true);
        } else {
          setIsDataUpdated(false);
          throw throwError(res);
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const getCountryList = () => {
    getTaxonomyCountryList().then((res) => {
      if (res?.status === 500 || res?.status === 401) {
        throw throwError(res);
      } else {
        if (res?.data && res?.data?.length > 0) {
          setCountryListData(res?.data);
          countryListRef.current = res?.data;
        }
      }
    });
  };

  const handleClickForRecentActivity = (columns, rows, index) => {
    if (
      (props?.isPlanExpire?.isActive === true ||
        props?.isPlanExpire?.isActive === "true") &&
      (props?.isPlanExpire?.isExtended === false ||
        props?.isPlanExpire?.isExtended === "false")
    ) {
      let _recentUserActivityQuery = recentUserActivityQuery.map((item) => {
        return JSON.parse(item);
      });
      localStorage.setItem(
        "searchPayload",
        JSON.stringify(_recentUserActivityQuery[index])
      );

      let findCountryNameForBl = rows?.query?.filter(
        (item) => item?.identifier == "SEARCH_COUNTRY"
      );

      let countryCode = countryListRef.current?.find((item) => {
        if (rows?.country === "bl") {
          return (
            item?.country?.toLowerCase() ==
            findCountryNameForBl[0].fieldValue[0].toLowerCase()
          );
        } else {
          return item?.country?.toLowerCase() == rows.country.toLowerCase();
        }
      });

      if (rows?.country === "Mirror_table") {
        navigate(`/consumers/mirrorData?isFromRecentSearch=${true}`);
      } else if (rows?.country === "bl") {
        navigate(
          `/countries/shipments/explorer?tradeType=${
            rows?.tradeType
          }&countryCode=${countryCode?.code_iso_3}&country=${
            findCountryNameForBl[0].fieldValue[0]
          }&blFlag=true&isFromRecentSearch=${true}`
        );
      } else {
        navigate(
          `/countries/shipments/explorer?tradeType=${
            rows?.tradeType
          }&countryCode=${countryCode?.code_iso_3}&country=${
            rows?.country
          }&blFlag=${
            rows?.country === "bl" ? true : false
          }&isFromRecentSearch=${true}`
        );
      }
      // window.location.reload();
    } else {
      setError(true);
      setErrorMsg({
        title: planExpiredWarning?.title,
        content: planExpiredWarning?.content,
      });
    }
  };

  const redirectPage = (e) => {
    if (
      (isPlanExpireRef.current?.isActive === "true" ||
        isPlanExpireRef.current?.isActive === true) &&
      (isPlanExpireRef.current?.isExtended === "false" ||
        isPlanExpireRef.current?.isExtended === false)
    ) {
      let country = {};
      let isFilterData = false;
      country = countryListRef.current?.find(
        (item) => item?.code_iso_3 === e?.payload?.countryCode
      );
      getSavedQuery(props.CLIENT_ACCOUNT_ID)
        .then((ele) => {
          if (ele?.status === 500 || ele?.status === 401) {
            throw throwError(ele);
          } else {
            if (ele?.data) {
              const allQueryData = [...ele.data];
              const filterQueryData =
                allQueryData &&
                allQueryData?.length > 0 &&
                allQueryData.find((data) => data?._id === e?._id);
              const checkedArr =
                filterQueryData?.payload?.matchExpressions.filter((e) => {
                  return e?.identifier?.split("_")[0] === "FILTER";
                });

              if (checkedArr.length > 0) {
                // checked any filter are there or not in clicked query
                isFilterData = true;
              }

              if (e?.payload?.mirrorData) {
                navigate(
                  `/consumers/mirrorData?id=${e?._id}&title=${e?.payload?.title}&filter=${isFilterData}`
                );
              } else {
                if (e?.payload?.country === "bl") {
                  navigate(
                    `/countries/shipments/explorer?tradeType=${
                      e?.payload?.tradeType
                    }&countryCode=${
                      e?.payload?.countryCode
                    }&country=${country?.country?.toUpperCase()}&id=${
                      e?._id
                    }&title=${
                      e?.payload.title
                    }&blFlag=true&filter=${isFilterData}`
                  );
                } else {
                  navigate(
                    `/countries/shipments/explorer?tradeType=${
                      e?.payload?.tradeType
                    }&countryCode=${
                      e?.payload?.countryCode
                    }&country=${e?.payload?.country.toUpperCase()}&id=${
                      e?._id
                    }&title=${
                      e?.payload?.title
                    }&blFlag=false&filter=${isFilterData}`
                  );
                }
              }
            } else {
              throw new Error();
            }
          }
        })
        .catch((err) => {
          setErrorForDashboard(err);
        });
    } else {
      setError(true);
      setErrorMsg({
        title: planExpiredWarning?.title,
        content: planExpiredWarning?.content,
      });
    }
  };

  const getSavedQueryData = () => {
    setLoading(true);
    getSaveQuery(null, props.CLIENT_ACCOUNT_ID)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          setLoading(false);
          if (e?.data) {
            if (e?.data.length <= 0) {
              setNoDataMsg("No Saved Query yet");
              setNoDataFound(true);
            } else {
              setNoDataFound(false);
            }
            row1 = [];
            arr = [...e.data];
            if (arr && arr?.length > 0) {
              arr.sort((a, b) => b?.created_at - a?.created_at);
            }
            arr &&
              arr?.length > 0 &&
              arr.forEach((ele, index) => {
                index++;
                let obj = {
                  title: ele.payload.title,
                  date: moment(new Date(ele.created_at)).format("DD-MM-YYYY"),
                  country: ele.payload.country,
                  tradeType: ele.payload.tradeType,
                  flag_uri: ele?.flag_uri,
                  action: (
                    <div>
                      <Tooltip title={"View SaveQuery"} arrow>
                        <RemoveRedEyeIcon
                          onClick={() => redirectPage(ele)}
                          style={{
                            color: "#005d91",
                            margin: "0px 5px 0px 4px",
                            height: "1em",
                            cursor: "pointer",
                          }}
                          fontSize="medium"
                          className="table-row-icon"
                        />
                      </Tooltip>
                      <Tooltip title={"Delete"} arrow>
                        <DeleteForeverIcon
                          onClick={(e) => deleteSaveQuery(ele?._id, index - 2)}
                          style={{
                            color: "red",
                            margin: "0px 5px 0px 4px",
                            height: "1em",
                            cursor: "pointer",
                          }}
                          fontSize="medium"
                          className="table-row-icon"
                        />
                      </Tooltip>
                    </div>
                  ),
                };
                row1.push(obj);
              });
            setQuery(row1);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const setErrorForDashboard = (err) => {
    const errorForLogger = `Dashboard Tables Component:- ${
      err?.message ? err.message : err.msg
    }`;
    logger(errorForLogger);
    setLoading(false);
    setError(true);
    setErrorMsg({
      title: err.status === 401 ? "Warning" : "Error",
      content:
        err.status === 500
          ? ErrorData.INTERNAL_SERVER
          : err.status === 401
          ? err.msg
          : ErrorData.SOMETHING_WENT_WRONG,
    });
  };

  const deleteSaveQuery = (id, index) => {
    setDeleteModal(true);
    setDeleteModalOpen(true);
    setDeleteId(id);
    setIndex(index);
  };

  useEffect(() => {
    if (recentSearchData && recentSearchData.length > 0) {
      let _recentSearchData = [...recentSearchData];
      setTopFiveRecentCountrySearch(_recentSearchData?.slice(0, 5));
    }
  }, [recentSearchData]);

  const savedClicked = () => {
    setDeleteModalOpen(false);
    props?.setLoading(true);
    props?.setLoadingMsg(Delete);

    deleteSaveQueryData(deleteId)
      .then((e) => {
        if (e?.status === 500 || e?.status === 401) {
          throw throwError(e);
        } else {
          if (e) {
            getSavedQueryData();
            setTimeout(() => {
              props?.setLoading(false);
            }, 2000);
          } else {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        setErrorForDashboard(err);
      });
  };

  const handleVideoIndexClickFromImage = (data, title) => {
    if (
      (isPlanExpireRef.current?.isActive === "true" ||
        isPlanExpireRef.current?.isActive === true) &&
      (isPlanExpireRef.current?.isExtended === "false" ||
        isPlanExpireRef.current?.isExtended === false)
    ) {
      navigate(`/help?videoName=${data}`);
    } else {
      setError(true);
      setErrorMsg({
        title: planExpiredWarning?.title,
        content: planExpiredWarning?.content,
      });
    }
  };

  const redirectPageForCountry = (e) => {
    if (
      (isPlanExpireRef.current?.isActive === "true" ||
        isPlanExpireRef.current?.isActive === true) &&
      (isPlanExpireRef.current?.isExtended === "false" ||
        isPlanExpireRef.current?.isExtended === false)
    ) {
      let findCountryNameForBl = e?.query?.filter(
        (item) => item?.identifier == "SEARCH_COUNTRY"
      );

      let countryCode = countryListRef.current?.find((item) => {
        if (e?.country === "bl") {
          return (
            item?.country?.toLowerCase() ==
            findCountryNameForBl[0].fieldValue[0].toLowerCase()
          );
        } else {
          return item?.country?.toLowerCase() == e?.country?.toLowerCase();
        }
      });

      if (e.country === "Mirror_table") {
        navigate(`/consumers/mirrorData`);
      } else if (e.country === "bl") {
        navigate(
          `/countries/shipments/explorer?tradeType=${e?.tradeType}&countryCode=${countryCode?.code_iso_3}&country=${findCountryNameForBl[0].fieldValue[0]}&blFlag=true`
        );
      } else {
        navigate(
          `/countries/shipments/explorer?tradeType=${
            e?.tradeType
          }&countryCode=${
            countryCode?.code_iso_3
          }&country=${e.country.toUpperCase()}&blFlag=false`
        );
      }
    } else {
      setError(true);
      setErrorMsg({
        title: planExpiredWarning?.title,
        content: planExpiredWarning?.content,
      });
    }
  };

  return (
    <>
      <div className="table-container">
        {error && <Error errorMsg={errorMsg} open={error} setOpen={setError} />}
        {!props?.sidebarContent?.HELP && loading && (
          <Stack
            sx={{ color: "grey.500" }}
            spacing={2}
            direction="row"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
            className="loader-dashboard-table"
          >
            <CircularProgress color="inherit" />
          </Stack>
        )}
        {props?.sidebarContent["SAVE QUERY"] && (
          <div className="save-query-grid width100">
            {getQuery?.map((item) => (
              <SaveQueryCard
                title={item?.title}
                date={item?.date}
                tradeType={item?.tradeType}
                country={item?.country}
                recordCount={item?.saveQueryCount}
                action={item?.action}
                countryFlag={item?.flag_uri}
                lastUpdateDate={item?.saveQueryDate}
                isFromSaveQuery={true}
              />
            ))}
          </div>
        )}
        {props?.sidebarContent["FAVORITE COMPANY"] && (
          <>
            <div className="fav-company-analysis-powerBi">
              {favCompanyChartData && favCompanyChartData.length > 0 && (
                <MyAreaChart
                  title="Favorite Company Trade Values Analysis"
                  data={favCompanyChartData}
                />
              )}
            </div>
            <div className="save-query-grid width100">
              <FavouriteCompanyTable
                isFromDashBoard={true}
                setNoDataFound={setNoDataFound}
                setLoadingForDashboardTable={setLoading}
                setNoDataMsg={setNoDataMsg}
              />
            </div>
          </>
        )}
        {props?.sidebarContent["RECENT SEARCHES"] &&
          dataTableRowValues &&
          dataTableRowValues.length > 0 && (
            <div className="frequent-search-table width100">
              {searchAnalysisChartData &&
                searchAnalysisChartData?.res?.length > 0 && (
                  <SearchAnalysisChart data={searchAnalysisChartData} />
                )}
              <StickyTable
                columns={TABLE_HEADER_RECENT_ACTIVITY}
                rows={dataTableRowValues}
                handleCollapseOpenModal={handleClickForRecentActivity}
                iSFromRecentSearch={true}
                height="570px"
              />
            </div>
          )}
        {props?.sidebarContent["RECOMMEND BUYERS/SELLERS"] &&
          recommendBuyerSeller &&
          recommendBuyerSeller.length > 0 &&
          isDataUpdated && (
            <div className="top-three-search-table width100">
              <DataTable
                columns={RECOMMENDED_TABLE_HEADER}
                rows={recommendBuyerSeller}
                scroll={true}
                height="550px"
                isFromDashboardTable={true}
                exploreCompanySearch={exploreCompanySearch}
              />
            </div>
          )}
        {props?.sidebarContent?.COMPETITORS &&
          competitorsData &&
          competitorsData.length > 0 &&
          isDataUpdated && (
            <div className="competitors-table width100">
              <DataTable
                columns={COMPETITORS_TABLE_HEADER}
                rows={competitorsData}
                scroll={true}
                height="550px"
                isFromDashboardTable={true}
                exploreCompanySearch={exploreCompanySearch}
              />
            </div>
          )}
        {props?.sidebarContent["POTENTIAL MARKET"] &&
          potentialMarketData &&
          potentialMarketData?.length > 0 &&
          isDataUpdated && (
            <div className="Potential-Market-table width100">
              <DataTable
                columns={POTENTIAL_TABLE_HEADER}
                rows={potentialMarketData}
                scroll={true}
                height="570px"
              />
            </div>
          )}
        {props?.sidebarContent["RECENT COUNTRY SEARCH"] &&
          topFiveRecentCountrySearch &&
          topFiveRecentCountrySearch.length > 0 && (
            <div className="recent-country-search ">
              <div className="table-border-body bg-primary recent-search-card-container">
                <RecentSearchedCountryCard
                  topFiveRecentCountrySearch={topFiveRecentCountrySearch}
                  countryListRef={countryListRef}
                  redirectPage={redirectPageForCountry}
                />
              </div>
            </div>
          )}
        {props?.sidebarContent?.HELP && (
          <div className="help-index-img">
            <VideoSvg
              handleClickVideoClickFromImage={handleVideoIndexClickFromImage}
              isFromDashBoard={true}
            />
          </div>
        )}
        {noDataFound && (
          <div
            style={{
              fontSize: "30px",
              display: "flex",
              justifyContent: "center",
              color: "rgb(108, 117, 125)",
              marginTop: "20px",
            }}
          >
            {noDataMsg}
          </div>
        )}
      </div>
      {deleteModal === true ? (
        <ConfirmationModal
          open={deleteModalOpen}
          header="Delete Query"
          modalBody={
            <div
              style={{
                padding: "15px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ margin: "10px" }}>
                <img src={DeleteIcon} alt="delete" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: 600,
                    color: "rgb(50, 58, 70)",
                  }}
                >
                  Delete
                </span>
                <div style={{ fontSize: "16px", marginTop: "20px" }}>
                  Are you sure you want to remove this SaveQuery ?
                </div>
              </div>
            </div>
          }
          saveText="Delete"
          width="600px"
          saveButtonShown={true}
          savedClicked={(val) => savedClicked(val)}
          saveButtonColor="#df0000c7"
          setOpen={setDeleteModalOpen}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default DashboardTable;
